import * as React from 'react';
import ReactDOM from 'react-dom';

import { library } from '@fortawesome/fontawesome-svg-core';
import { fal } from '@fortawesome/pro-light-svg-icons';
import { far } from '@fortawesome/pro-regular-svg-icons';
import { fas } from '@fortawesome/pro-solid-svg-icons';

import { Provider } from 'react-redux';

import Root from './components/Root';
import registerServiceWorker from './registerServiceWorker';

import { IS_PROD } from './helpers/constants';

import 'animate.css/animate.min.css';
import 'emoji-mart/css/emoji-mart.css';
import 'normalize.css';
import 'rc-tooltip/assets/bootstrap.css';

import './brand.scss';
import './index.scss';

import { configureStore } from './store/configureStore';
const store = configureStore();

if (IS_PROD) {
  const version = process.env.REACT_APP_VERSION;
  const pipeline = process.env.REACT_APP_PIPELINE;
  const commit = process.env.REACT_APP_COMMIT;

  console.log(`Keero v${version}.${pipeline} (${commit})`);
}

library.add(fal, far, fas);

ReactDOM.render(
  <Provider store={store}>
    <Root />
  </Provider>,
  document.getElementById('root'),
);

registerServiceWorker();
