import { connect } from 'react-redux';
import { Action, Dispatch } from 'redux';

import Root from './component';

import { settings, toasts } from '../../store/actions';
const { setTheme } = settings;
const { push, shift } = toasts;

import State from '../../interfaces/State';
import Toast from '../../interfaces/Toast';

const mapStateToProps = (state: State) => {
  return {
    settings: state.settings,
    team: state.team,
    toasts: state.toasts,
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>) => {
  return {
    addToast: (toast: Toast) => dispatch(push(toast)),
    setTheme: (theme: string) => dispatch(setTheme(theme)),
    shiftToast: () => dispatch(shift()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Root);
