import * as React from 'react';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './style.scss';

const {
  NvButton,
  NvButtonGroup,
} = require('nv-react-components/partners/nimvelo');

import { Props, State } from './interfaces';

let toastNode: HTMLDivElement; // For event listeners

export default class Toast extends React.Component<Props, {}> {
  public readonly state: State = {
    timeoutID: undefined,
  };

  public componentDidMount(): void {
    this.scheduleDismiss(this.props.delay);

    if (toastNode && toastNode.addEventListener) {
      toastNode.addEventListener('mouseover', this.cancelScheduledDismiss);

      if (this.props.delay > 0) {
        toastNode.addEventListener('mouseout', () => {
          this.scheduleDismiss(1000);
        });
      }
    }
  }

  public componentDidUpdate(prevProps: Props): void {
    if (prevProps.id !== this.props.id) {
      this.cancelScheduledDismiss();
      this.scheduleDismiss(this.props.delay);
    }
  }

  public componentWillUnmount(): void {
    if (toastNode && toastNode.removeEventListener) {
      toastNode.removeEventListener('mouseover', this.cancelScheduledDismiss);
      toastNode.removeEventListener('mouseout', () => {
        this.scheduleDismiss(1000);
      });
    }
  }

  public render(): JSX.Element {
    let faIcon: IconProp;
    switch (this.props.type) {
      case 'positive':
        faIcon = 'check';
        break;
      case 'negative':
        faIcon = 'exclamation';
        break;
      case 'action':
        faIcon = 'question';
        break;
      case 'primary':
      case 'secondary':
      case 'warning':
      default:
        faIcon = 'info';
    }

    const icon = (
      <span className='toast-icon'>
        <FontAwesomeIcon icon={faIcon} />
      </span>
    );

    const closer = (
      <span className='toast-closer' onClick={this.props.shift} title='Dismiss'>
        <FontAwesomeIcon icon='times' />
      </span>
    );

    const propsButtons = this.props.buttons || [];

    const buttons: JSX.Element = (
      <NvButtonGroup className='toast-buttons'>
        {propsButtons.map((button, index: number) => {
          const onClick = () => {
            if (button.onClick) {
              button.onClick();
            }

            this.props.shift();
          };

          return (
            <NvButton
              inverted={button.inverted}
              key={index}
              kind={button.kind || this.props.type}
              onClick={onClick}
            >
              {button.text}
            </NvButton>
          );
        })}
      </NvButtonGroup>
    );

    return (
      <div
        className={`toast-container${this.props.removing ? ' removing' : ''}`}
        ref={(node: HTMLDivElement) => {
          toastNode = node;
        }}
      >
        <div className={`toast ${this.props.type}`} key={this.props.id}>
          {icon}
          <span className='toast-message'>{this.props.message}</span>
          {propsButtons.length > 0 ? buttons : closer}
        </div>
      </div>
    );
  }

  private readonly scheduleDismiss = (delay: number): void => {
    if (delay > 0) {
      const timeoutID = window.setTimeout(this.props.shift, delay);
      this.setState({ timeoutID });
    }
  };

  private readonly cancelScheduledDismiss = (): void => {
    window.clearTimeout(this.state.timeoutID);
  };
}
