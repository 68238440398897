import Conversation from '../interfaces/Chat/Conversation';
import lcFile from '../interfaces/Chat/File';
import lcFiles from '../interfaces/Chat/Files';
import Message from '../interfaces/Chat/Message';
import UserMessage from '../interfaces/Chat/UserMessage';

interface ResponseBody {
  items: any[]; // tslint:disable-line no-any
  navigation: {
    nextCursor?: string;
  };
  meta?: {
    unreadCount?: number;
    oldestUnreadId?: string;
    oldestUnreadTimestamp?: string;
  };
}

interface CustomHeaders extends Partial<Headers> {
  Accept?: string;
  'Access-Control-Allow-Origin'?: string;
  Authorization?: string;
  'Content-Type'?: string;
  'X-Partner-Id'?: string;
  'X-WWW-Authenticate'?: string;
}

/**
 * Fetch something from Nimvelo API
 * Provides all necessary headers
 *
 * @param resource E.g. 'customers/5'
 * @param accessToken Encoded JWT
 * @param method POST, GET, PUT, DELETE
 * @param body JSON data. Ignored unless method is PUT or POST
 * @param customHeaders Additional request headers
 * @return Promise
 */
export const lcFetch = (
  resource: string,
  accessToken: string = '',
  method: string = 'GET',
  body?: any, // tslint:disable-line no-any
  customHeaders?: CustomHeaders,
  params?: {
    cursor?: string;
    latest?: string;
    limit?: number;
    oldest?: string;
    page?: number;
    pageSize?: number;
  },
  // tslint:disable-next-line no-any
): Promise<any> =>
  new Promise((resolve: () => void, reject: (error: Error) => void) => {
    const APIRoot = process.env.REACT_APP_CHAT_API;

    // TODO retype this
    // tslint:disable-next-line no-any
    const options: any = { method };
    // const options: RequestInit = { method };

    const headers: CustomHeaders = {
      Accept: 'application/json',
      'Access-Control-Allow-Origin': 'origin',
      'Content-Type': 'application/json',
      'X-WWW-Authenticate': 'false',
      ...customHeaders,
    };

    if (accessToken) {
      headers.Authorization = `Bearer ${accessToken}`;
    }

    // Unset headers which are passed as undefined
    for (const key in customHeaders) {
      if (customHeaders[key] === undefined) {
        delete headers[key];
      }
    }

    options.headers = headers;

    if (method === 'PUT' || method === 'POST') {
      if (body) {
        options.body = body;

        if (!(body instanceof FormData)) {
          try {
            options.body = JSON.stringify(body);
          } catch (error) {
            // ¯\_(ツ)_/¯
          }
        }
      } else {
        reject(new Error('No body data'));
      }
    }

    let fullResource = `${APIRoot}/${resource}`;

    // Stringify and append query params, if they exist
    if (params) {
      const paramsArr = Object.entries(params).filter(
        ([key, value]) => value !== undefined,
      );
      let paramString = '';

      if (paramsArr.length > 0) {
        paramString = paramsArr.map(([key, val]) => `${key}=${val}`).join('&');

        fullResource = `${fullResource}?${paramString}`;
      }
    }

    fetch(fullResource, options)
      .then((response: Response) => {
        if (response.status < 400) {
          if (response.status === 204) {
            // 204 = No Content
            return response.text();
          }

          return response.json();
        } else {
          return response
            .json()
            .then((data) => {
              const { title, detail } = data.errors[0];
              return reject(new Error(`${title} - ${detail}`));
            })
            .catch((err) => {
              return reject(
                new Error(`${response.status}: ${response.statusText}`),
              );
            });
        }
      })
      .then(resolve)
      .catch(reject);
  });

export const lcFetchAllPages = async (
  resource: string,
  accessToken: string = '',
  method: string = 'GET',
  body?: any, // tslint:disable-line no-any
  customHeaders?: Partial<Headers>,
  maxPages: number = 10, // For safety
  useCursor: boolean = false,
  // tslint:disable-next-line no-any
): Promise<any> => {
  const limit = 200;
  let page = 1;
  let combinedData: any[] = []; // tslint:disable-line no-any
  let isDone = false;
  let nextPageCursor;

  if (useCursor) {
    while (!isDone && page <= maxPages) {
      // eslint-disable-next-line no-await-in-loop
      const data: ResponseBody = await lcFetch(
        resource,
        accessToken,
        method,
        body,
        customHeaders,
        {
          cursor: nextPageCursor,
          limit,
        },
      );

      combinedData = [...combinedData, ...data.items];

      if (data.navigation && data.navigation.nextCursor) {
        nextPageCursor = data.navigation.nextCursor;
        page += 1;
      } else {
        isDone = true;
      }
    }
  } else {
    while (!isDone && page <= maxPages) {
      // eslint-disable-next-line no-await-in-loop
      const data: ResponseBody = await lcFetch(
        resource,
        accessToken,
        method,
        body,
        customHeaders,
        {
          page,
          pageSize: limit,
        },
      );

      combinedData = [...combinedData, ...data.items];

      if (data.items.length < limit) {
        isDone = true;
      } else {
        page += 1;
      }
    }
  }

  return {
    items: combinedData,
  };
};

/**
 * Send lobby message
 *
 * @param teamId Team ID
 * @param text Text string
 * @param accessToken API access token JWT
 * @return Promise
 */
export const sendLobbyMessage = (
  teamId: string = '',
  text: string = '',
  accessToken: string = '',
): Promise<Message> => {
  return lcFetch(
    `teams/${teamId}/conversations/lobby/messages`,
    accessToken,
    'POST',
    {
      text: text.trim(),
    },
  );
};

/**
 * Update specific lobby message
 *
 * @param teamId Team ID
 * @param message New message object
 * @param accessToken API access token JWT
 * @return Promise
 */
export const updateLobbyMessage = (
  teamId: string = '',
  message: Message,
  accessToken: string = '',
): Promise<Message> => {
  return lcFetch(
    `teams/${teamId}/conversations/lobby/${message.id}`,
    accessToken,
    'PUT',
    {
      ...message,
      text: message.text.trim(),
    },
  );
};

/**
 * Remove specific lobby message
 *
 * @param teamId Team ID
 * @param message Message ID
 * @param accessToken API access token JWT
 * @return Promise
 */
export const deleteLobbyMessage = (
  teamId: string = '',
  message: string = '',
  accessToken: string = '',
): Promise<Message> => {
  return lcFetch(
    `teams/${teamId}/conversations/lobby/${message}`,
    accessToken,
    'DELETE',
  );
};

/**
 * Send message
 *
 * @param teamId Team ID
 * @param conversationId Conversation ID
 * @param text Text string
 * @param accessToken API access token JWT
 * @return Promise
 */
export const sendMessage = (
  teamId: string = '',
  conversationId: string = '',
  text: string = '',
  accessToken: string = '',
): Promise<Message> => {
  return lcFetch(
    `teams/${teamId}/conversations/${conversationId}/messages`,
    accessToken,
    'POST',
    {
      text: text.trim(),
    },
  );
};

/**
 * Update specific message
 *
 * @param teamId Team ID
 * @param conversationId Conversation ID
 * @param message New message object
 * @param accessToken API access token JWT
 * @return Promise
 */
export const updateMessage = (
  teamId: string = '',
  conversationId: string = '',
  message: Message,
  accessToken: string = '',
): Promise<Message> => {
  return lcFetch(
    `teams/${teamId}/conversations/${conversationId}/messages/${message.id}`,
    accessToken,
    'PUT',
    {
      ...message,
      text: message.text.trim(),
    },
  );
};

/**
 * Remove specific message
 *
 * @param teamId Team ID
 * @param conversationId Conversation ID
 * @param message Message ID
 * @param accessToken API access token JWT
 * @return Promise
 */
export const deleteMessage = (
  teamId: string = '',
  conversationId: string = '',
  message: string = '',
  accessToken: string = '',
): Promise<Message> => {
  return lcFetch(
    `teams/${teamId}/conversations/${conversationId}/messages/${message}`,
    accessToken,
    'DELETE',
  );
};

/**
 * Send user message
 *
 * @param teamId Team ID
 * @param memberId Member ID
 * @param text Text string
 * @param accessToken API access token JWT
 * @return Promise
 */
export const sendUserMessage = (
  teamId: string = '',
  memberId: string = '',
  text: string = '',
  accessToken: string = '',
): Promise<UserMessage> => {
  return lcFetch(
    `teams/${teamId}/members/${memberId}/messages`,
    accessToken,
    'POST',
    {
      text: text.trim(),
    },
  );
};

/**
 * Update specific user message
 *
 * @param teamId Team ID
 * @param memberId Member ID
 * @param message New message object
 * @param accessToken API access token JWT
 * @return Promise
 */
export const updateUserMessage = (
  teamId: string = '',
  memberId: string = '',
  message: UserMessage,
  accessToken: string = '',
): Promise<UserMessage> => {
  return lcFetch(
    `teams/${teamId}/members/${memberId}/messages/${message.id}`,
    accessToken,
    'PUT',
    {
      ...message,
      text: message.text.trim(),
    },
  );
};

/**
 * Remove specific user message
 *
 * @param teamId Team ID
 * @param memberId Member ID
 * @param message Message ID
 * @param accessToken API access token JWT
 * @return Promise
 */
export const deleteUserMessage = (
  teamId: string = '',
  memberId: string = '',
  message: string = '',
  accessToken: string = '',
): Promise<UserMessage> => {
  return lcFetch(
    `teams/${teamId}/members/${memberId}/messages/${message}`,
    accessToken,
    'DELETE',
  );
};

/**
 * Create conversation
 *
 * @param teamId Team ID
 * @param name Conversation name
 * @param accessToken API access token JWT
 * @return Promise
 */
export const createConversation = (
  teamId: string = '',
  accessToken: string = '',
  data: {
    isPrivate?: boolean;
    name?: string;
    participants?: string[];
    private?: boolean;
    tags?: string[];
  } = {},
): Promise<Conversation> => {
  if (data.hasOwnProperty('isPrivate')) {
    data.private = data.isPrivate;
    delete data.isPrivate;
  }

  return lcFetch(`teams/${teamId}/conversations`, accessToken, 'POST', data);
};

/**
 * Remove specific conversation
 *
 * @param teamId Team ID
 * @param conversationId Conversation ID
 * @param accessToken API access token JWT
 * @return Promise
 */
export const deleteConversation = (
  teamId: string,
  conversationId: string = 'lobby',
  accessToken: string,
): Promise<Conversation> => {
  return lcFetch(
    `teams/${teamId}/conversations/${conversationId}`,
    accessToken,
    'DELETE',
  );
};

/**
 * Upload a file to a conversation
 *
 * @param file File binary
 * @param teamId Team ID
 * @param conversationId Conversation UUID
 * @param accessToken API access token JWT
 * @return Promise with File
 */
export const uploadFile = (
  file: File,
  caption: string,
  teamId: string,
  conversationId: string = 'lobby',
  accessToken: string,
): Promise<lcFile> => {
  const formData = new FormData();
  formData.append('conversation', conversationId);
  formData.append('post', 'true');
  formData.append('file', file, file.name);
  formData.append('caption', caption);

  return lcFetch(
    `teams/${teamId}/conversations/${conversationId}/files`,
    accessToken,
    'POST',
    formData,
    {
      'Content-Type': undefined,
    },
  );
};

/**
 * Upload a file to a conversation
 *
 * @param file File binary
 * @param caption Message text
 * @param teamId Team ID
 * @param memberId Member ID of other participant of DM
 * @param accessToken API access token JWT
 * @return Promise with File
 */
export const uploadDMFile = (
  file: File,
  caption: string,
  teamId: string,
  memberId: string,
  accessToken: string,
): Promise<lcFile> => {
  const formData = new FormData();
  formData.append('conversation', memberId); // memberId?
  formData.append('post', 'true');
  formData.append('file', file, file.name);
  formData.append('caption', caption);

  return lcFetch(
    `teams/${teamId}/members/${memberId}/files`,
    accessToken,
    'POST',
    formData,
    {
      'Content-Type': undefined,
    },
  );
};

/**
 * Delete a file from a conversation
 *
 * @param fileId File ID
 * @param teamId Team ID
 * @param conversationId Conversation UUID
 * @param accessToken API access token JWT
 * @return Promise with File
 */
export const deleteFile = (
  fileId: string,
  teamId: string,
  conversationId: string = 'lobby',
  accessToken: string,
): Promise<lcFile> => {
  return lcFetch(
    `teams/${teamId}/conversations/${conversationId}/files/${fileId}`,
    accessToken,
    'DELETE',
  );
};

/**
 * Delete a file from a dm
 *
 * @param fileId File ID
 * @param teamId Team ID
 * @param memberId Member ID of other participant of DM
 * @param accessToken API access token JWT
 * @return Promise with File
 */
export const deleteDMFile = (
  fileId: string,
  teamId: string,
  memberId: string,
  accessToken: string,
): Promise<lcFile> => {
  return lcFetch(
    `teams/${teamId}/members/${memberId}/files/${fileId}`,
    accessToken,
    'DELETE',
  );
};

/**
 * List a conversation's files
 *
 * @param teamId Team ID
 * @param conversationId Conversation ID
 * @param accessToken API access token JWT
 * @return Promise
 */
export const listFiles = (
  teamId: string,
  conversationId: string = 'lobby',
  accessToken: string,
): Promise<lcFiles> => {
  return lcFetchAllPages(
    `teams/${teamId}/conversations/${conversationId}/files`,
    accessToken,
  );
};

/**
 * List a dm's files
 *
 * @param teamId Team ID
 * @param memberId Member ID of other participant of DM
 * @param accessToken API access token JWT
 * @return Promise
 */
export const listDMFiles = (
  teamId: string,
  memberId: string = '',
  accessToken: string,
): Promise<lcFiles> => {
  return lcFetchAllPages(
    `teams/${teamId}/members/${memberId}/files`,
    accessToken,
  );
};
