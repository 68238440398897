import deepFreeze from 'deep-freeze';
import { merge } from 'lodash';

import { Conversations } from '../actions';

import Conversation from '../../interfaces/Chat/Conversation';
import ConversationInterface from '../../interfaces/Chat/Conversation';
import ConversationsInterface from '../../interfaces/Chat/Conversations';
import ConversationsResponse from '../../interfaces/Chat/ConversationsResponse';
import Message from '../../interfaces/Chat/Message';
import MessagesResponse from '../../interfaces/Chat/MessagesResponse';

import { ActionInterface } from '../../helpers/actionBuilder';
import { IS_PROD } from '../../helpers/constants';
import { deduplicate } from '../../helpers/deduplicate';

export const initialState: ConversationsInterface = {
  fetching: false,
  fetchingInbox: false,
  items: [],
  message: '',
};

export const initialConversation: ConversationInterface = {
  archived: false,
  created: new Date(),
  fetching: false,
  fetchingMessages: false,
  id: '',
  lastActive: new Date(),
  message: '',
  messages: [],
  name: '',
  owner: '',
  participants: [],
  private: false,
  tags: [],
  team: '',
  type: '',
  typingUsers: [],
  unreadMessagesCount: 0,
};

/**
 * Empty downloaded conversations list
 */
const clear = (): ConversationsInterface => initialState;

/**
 * Initiate fetching
 */
const fetching = (
  conversations: ConversationsInterface = initialState,
): ConversationsInterface => {
  return merge({}, conversations, { fetching: true });
};

/**
 * Complete fetching, success
 */
const receiveConversations = (
  conversations: ConversationsInterface = initialState,
  fetchedConversations: ConversationsResponse,
): ConversationsInterface => {
  if (!fetchedConversations.items) {
    return conversations;
  }

  const messagesMap = new Map();

  conversations.items.forEach((conversation) => {
    messagesMap.set(conversation.id, [...conversation.messages]);
  });

  let items = fetchedConversations.items.concat(conversations.items);

  items = deduplicate(items);

  items = items.map((conversation) => {
    const messages = messagesMap.get(conversation.id) || [];

    const unreadMessagesCount = messages.filter(
      (message: Message) => !message.read,
    ).length;

    return {
      ...initialConversation,
      ...conversation,
      messages,
      unreadMessagesCount,
    };
  });

  items = items.sort((a, b) => {
    if (a.lastActive < b.lastActive) {
      return 1;
    }
    if (a.lastActive > b.lastActive) {
      return -1;
    }
    return 0;
  });

  const nextConversations = {
    ...conversations,
    items,
  };

  return nextConversations;
};

/**
 * Complete fetching, success
 */
const fetchingSucceeded = (
  conversations: ConversationsInterface = initialState,
): ConversationsInterface => {
  return merge({}, conversations, { fetching: false });
};

/**
 * Complete fetching, failure
 */
const fetchingFailed = (
  conversations: ConversationsInterface = initialState,
  message: string = initialState.message,
): ConversationsInterface => {
  return merge({}, conversations, { fetching: false, message });
};

/**
 * Initiate fetching
 */
const fetchingInbox = (
  conversations: ConversationsInterface = initialState,
): ConversationsInterface => {
  return merge({}, conversations, { fetchingInbox: true });
};

/**
 * Complete inbox fetching, success
 */
const fetchingInboxSucceeded = (
  conversations: ConversationsInterface = initialState,
): ConversationsInterface => {
  return merge({}, conversations, { fetchingInbox: false });
};

/**
 * Complete fetching, failure
 */
const fetchingInboxFailed = (
  conversations: ConversationsInterface = initialState,
  message: string = initialState.message,
): ConversationsInterface => {
  return merge({}, conversations, { fetchingInbox: false, message });
};

/**
 * TODO write jsdoc
 */
const recalculateUnread = (
  conversations: ConversationsInterface = initialState,
  conversationId: string,
): ConversationsInterface => {
  const conversation = conversations.items.find(
    (conversation) => conversation.id === conversationId,
  );

  const nextConversation = {
    ...conversation,
    unreadMessagesCount: conversation.messages.filter(
      (message) => !message.read,
    ).length,
  };

  // Update conversation
  const nextConversations = conversations.items.map(
    (x) =>
      x.id === conversation.id
        ? nextConversation // Update conversation
        : x, // Return existing conversation
  );

  return {
    ...conversations,
    items: nextConversations,
  };
};

/**
 * Conversation deleted
 */
const conversationDeleted = (
  conversations: ConversationsInterface = initialState,
  deletedConversation: Conversation = initialConversation,
): ConversationsInterface => {
  const items = conversations.items.filter((conversation) => {
    return conversation.id !== deletedConversation.id;
  });

  return {
    ...conversations,
    items,
  };
};

/**
 * Clear conversation
 */
const clearConversation = (
  conversations: ConversationsInterface = initialState,
  deletedConversation: Conversation = initialConversation,
): ConversationsInterface => {
  const items = conversations.items.filter(
    (conversation) => conversation.id !== deletedConversation.id,
  );

  return {
    ...conversations,
    items,
  };
};

/**
 * Find given conversation, set fetching to true
 */
const fetchingConversation = (
  conversations: ConversationsInterface = initialState,
  conversationId: string,
): ConversationsInterface => ({
  ...conversations,
  items: conversations.items.map((conversation) =>
    conversation.id === conversationId
      ? {
          ...conversation,
          fetching: true,
        }
      : conversation,
  ),
});

/**
 * Complete fetching conversation, success
 */
const fetchingConversationSucceeded = (
  conversations: ConversationsInterface = initialState,
  conversationId: string,
): ConversationsInterface => ({
  ...conversations,
  items: conversations.items.map((conversation) =>
    conversation.id === conversationId
      ? {
          ...conversation,
          fetching: false,
        }
      : conversation,
  ),
});

/**
 * Complete fetching conversation, failure
 */
const fetchingConversationFailed = (
  conversations: ConversationsInterface = initialState,
  {
    conversationId,
    message = initialConversation.message,
  }: {
    conversationId: string;
    message: string;
  },
): ConversationsInterface => ({
  ...conversations,
  items: conversations.items.map((conversation) =>
    conversation.id === conversationId
      ? {
          ...conversation,
          fetching: false,
          message,
        }
      : conversation,
  ),
});

/**
 * Find given conversation and delete the given message
 */
const conversationMessageDeleted = (
  conversations: ConversationsInterface = initialState,
  messageToDelete: Message,
): ConversationsInterface => {
  return {
    ...conversations,
    items: conversations.items.map((conversation) =>
      conversation.id === messageToDelete.conversation
        ? {
            ...conversation,
            messages: conversation.messages.filter((message) => {
              return message.id !== messageToDelete.id;
            }),
          }
        : conversation,
    ),
  };
};

/**
 * Receive the messages into their correct conversations
 */
const conversationMessagesReceived = (
  conversations: ConversationsInterface = initialState,
  fetchedMessages: MessagesResponse,
): ConversationsInterface => {
  if (!fetchedMessages.items) {
    return conversations;
  }

  let nextConversations = conversations.items;

  const messages = fetchedMessages.items;

  // Oh boy here we go... 🐸 🚲

  // For each message try to find the related conversation
  messages.forEach((message) => {
    // Ensure data types
    message.posted = new Date(message.posted);
    if (message.edited) {
      message.edited = new Date(message.edited);
    }

    // See if this message references a conversation we already have
    const conversation = nextConversations.find(
      (x) => x.id === message.conversation,
    );

    if (conversation) {
      // We have the conversation, so check if this id exists in it's messages
      const convHasMessage = conversation.messages.some(
        (x) => x.id === message.id,
      );

      // Does the conversation already have this message?
      const nextMessages = convHasMessage
        ? // It does, so replace the existing message with newly received one
          conversation.messages.map((x) =>
            x.id === message.id
              ? {
                  ...message,
                  read: x.read || message.read, // Don't let a message be marked as unread
                }
              : x,
          )
        : [
            // It doesn't, so append new message to existing messages
            ...conversation.messages,
            message,
          ];

      // Sort the messages by posted date
      const sortedMessages = nextMessages.sort(
        (a: Message, b: Message): number =>
          new Date(a.posted).getTime() - new Date(b.posted).getTime(),
      );

      // Our new conversation object
      const nextConversation = {
        ...conversation,
        messages: [...sortedMessages],
        unreadMessagesCount: sortedMessages.filter((x) => !x.read).length,
      };

      // Update our conversation in nextConversations
      nextConversations = nextConversations.map(
        (x) =>
          x.id === nextConversation.id
            ? nextConversation // Replace witn newly updated conversation
            : x, // Return existing conversation
      );
    } else {
      // Create a new conversation, set it's id and messages
      const nextConversation = {
        ...initialConversation,
        id: message.conversation,
        messages: [message],
        unreadMessagesCount: message.read ? 0 : 1,
      };

      // Add the new conversation to the existing conversations
      nextConversations = [...nextConversations, nextConversation];
    }
  });

  // Aaaand we're done! 🅾️ 💩 ❓ 🆙

  const dedupedConversations = deduplicate(nextConversations);

  return {
    ...conversations,
    items: dedupedConversations,
  };
};

/**
 * Update a conversation message
 */
const conversationMessageUpdated = (
  conversations: ConversationsInterface = initialState,
  message: Message,
): ConversationsInterface => {
  const conversation = conversations.items.find(
    (x) => x.id === message.conversation,
  );

  // Update message
  const messages = conversation.messages.map(
    (x) =>
      x.id === message.id
        ? message // Update message
        : x, // Return existing message
  );

  const nextConversation = {
    ...conversation,
    messages,
    unreadMessagesCount: messages.filter((x) => !x.read).length,
  };

  // Update conversation
  const nextConversations = conversations.items.map(
    (x) =>
      x.id === conversation.id
        ? nextConversation // Update conversation
        : x, // Return existing conversation
  );

  return {
    ...conversations,
    items: nextConversations,
  };
};

/**
 * Receive a conversation
 */
const conversationReceived = (
  conversations: ConversationsInterface = initialState,
  conversation: Conversation,
): ConversationsInterface => {
  const existingConversation = conversations.items.find(
    (x) => x.id === conversation.id,
  );

  // Let's get this out of the way
  if (!existingConversation) {
    // If the conversation doesn't already exist, just append it
    return {
      ...conversations,
      items: [...conversations.items, conversation],
    };
  }

  // If we're here the conversation does already exist, so let's update it
  const nextConversation = {
    ...conversation,
    messages: existingConversation.messages, // Keep the messages
    unreadMessagesCount: existingConversation.messages.filter((x) => !x.read)
      .length,
  };

  const nextConversations = conversations.items.map((x) =>
    x.id === nextConversation.id ? nextConversation : x,
  );

  return {
    ...conversations,
    items: nextConversations,
  };
};

/**
 * Conversation user started typing
 */
const conversationUserStartedTyping = (
  conversations: ConversationsInterface = initialState,
  data: {
    conversation: string;
    user: string;
  },
): ConversationsInterface => {
  const conversation = conversations.items.find(
    (x) => x.id === data.conversation,
  );
  // If we can't find the conversation, just return
  if (!conversation) {
    return {
      ...conversations,
    };
  }

  let { typingUsers } = conversation;

  // Add the typing user
  if (!typingUsers.includes(data.user)) {
    typingUsers = [...typingUsers, data.user];
  }

  // Build the next conversation
  const nextConversation = {
    ...conversation,
    typingUsers,
  };

  // Replace the existing conversation with the updated conversation
  const nextItems = conversations.items.map((x) =>
    x.id === data.conversation ? nextConversation : x,
  );

  return {
    ...conversations,
    items: nextItems,
  };
};

/**
 * Conversation user stopped typing
 */
const conversationUserStoppedTyping = (
  conversations: ConversationsInterface = initialState,
  data: {
    conversation: string;
    user: string;
  },
): ConversationsInterface => {
  const conversation = conversations.items.find(
    (x) => x.id === data.conversation,
  );
  // If we can't find the conversation, just return
  if (!conversation) {
    return {
      ...conversations,
    };
  }

  const typingUsers = conversation.typingUsers.filter((x) => x !== data.user);

  // Build the next conversation
  const nextConversation = {
    ...conversation,
    typingUsers,
  };

  // Replace the existing conversation with the updated conversation
  const nextItems = conversations.items.map((x) =>
    x.id === data.conversation ? nextConversation : x,
  );

  return {
    ...conversations,
    items: nextItems,
  };
};

/**
 * Find given conversation, set fetchingMessages to true
 */
const fetchingConversationMessages = (
  conversations: ConversationsInterface = initialState,
  conversationId: string,
): ConversationsInterface => ({
  ...conversations,
  items: conversations.items.map((conversation) =>
    conversation.id === conversationId
      ? {
          ...conversation,
          fetchingMessages: true,
        }
      : conversation,
  ),
});

/**
 * Complete fetching conversation messages, success
 */
const fetchingConversationMessagesSucceeded = (
  conversations: ConversationsInterface = initialState,
  conversationId: string,
): ConversationsInterface => ({
  ...conversations,
  items: conversations.items.map((conversation) =>
    conversation.id === conversationId
      ? {
          ...conversation,
          fetchingMessages: false,
        }
      : conversation,
  ),
});

/**
 * Complete fetching conversation messages, failure
 */
const fetchingConversationMessagesFailed = (
  conversations: ConversationsInterface = initialState,
  {
    conversationId,
    message = initialConversation.message,
  }: {
    conversationId: string;
    message: string;
  },
): ConversationsInterface => ({
  ...conversations,
  items: conversations.items.map((conversation) =>
    conversation.id === conversationId
      ? {
          ...conversation,
          fetchingMessages: false,
          message,
        }
      : conversation,
  ),
});

/**
 * User joined a conversation
 */
const userJoined = (
  conversations: ConversationsInterface = initialState,
  {
    conversationId,
    userId,
  }: {
    conversationId: string;
    userId: string;
  },
): ConversationsInterface => {
  const conversation = conversations.items.find(
    (conversation) => conversation.id === conversationId,
  );

  if (!conversation) {
    return conversations;
  }

  let { participants } = conversation;

  participants = participants.concat(userId);

  const nextConversation = {
    ...conversation,
    participants,
  };

  return {
    ...conversations,
    items: conversations.items.map((conversation) => {
      if (conversation.id === conversationId) {
        return nextConversation;
      }

      return conversation;
    }),
  };
};

/**
 * User left a conversation
 */
const userLeft = (
  conversations: ConversationsInterface = initialState,
  {
    conversationId,
    userId,
  }: {
    conversationId: string;
    userId: string;
  },
): ConversationsInterface => {
  const conversation = conversations.items.find(
    (conversation) => conversation.id === conversationId,
  );

  if (!conversation) {
    return conversations;
  }

  let { participants } = conversation;

  participants = participants.filter((participant) => participant !== userId);

  const nextConversation = {
    ...conversation,
    participants,
  };

  return {
    ...conversations,
    items: conversations.items.map((conversation) => {
      if (conversation.id === conversationId) {
        return nextConversation;
      }

      return conversation;
    }),
  };
};

export default (
  state = initialState,
  action: ActionInterface,
): ConversationsInterface => {
  if (!IS_PROD) {
    // Ensure state never gets mutated
    deepFreeze(state);
  }

  switch (action.type) {
    case Conversations.clear:
      return clear();

    case Conversations.fetching:
      return fetching(state);

    case Conversations.receive:
      return receiveConversations(state, action.payload);

    case Conversations.fetchingDone:
      if (action.error) {
        return fetchingFailed(state, action.payload.message);
      } else {
        return fetchingSucceeded(state);
      }

    case Conversations.inbox.fetching:
      return fetchingInbox(state);

    case Conversations.inbox.fetchingDone:
      if (action.error) {
        return fetchingInboxFailed(state, action.payload.message);
      } else {
        return fetchingInboxSucceeded(state);
      }

    case Conversations.inbox.received:
      return conversationMessagesReceived(state, action.payload);

    case Conversations.conversation.recalculateUnread:
      return recalculateUnread(state, action.payload);

    case Conversations.conversation.delete:
      return conversationDeleted(state, action.payload);

    case Conversations.conversation.clear:
      return clearConversation(state, action.payload);

    case Conversations.conversation.fetching:
      return fetchingConversation(state, action.payload);

    case Conversations.conversation.fetchingDone:
      if (action.error) {
        return fetchingConversationFailed(state, action.payload);
      } else {
        return fetchingConversationSucceeded(state, action.payload);
      }

    case Conversations.conversation.messages.deleted:
      return conversationMessageDeleted(state, action.payload);

    case Conversations.conversation.messages.received:
      return conversationMessagesReceived(state, action.payload);

    case Conversations.conversation.messages.fetching:
      return fetchingConversationMessages(state, action.payload);

    case Conversations.conversation.messages.fetchingDone:
      if (action.error) {
        return fetchingConversationMessagesFailed(state, action.payload);
      } else {
        return fetchingConversationMessagesSucceeded(state, action.payload);
      }

    case Conversations.conversation.messages.updated:
      return conversationMessageUpdated(state, action.payload);

    case Conversations.conversation.received:
      return conversationReceived(state, action.payload);

    case Conversations.conversation.userStartedTyping:
      return conversationUserStartedTyping(state, action.payload);

    case Conversations.conversation.userStoppedTyping:
      return conversationUserStoppedTyping(state, action.payload);

    case Conversations.conversation.userJoined:
      return userJoined(state, action.payload);

    case Conversations.conversation.userLeft:
      return userLeft(state, action.payload);

    default:
      return state;
  }
};
