import State from '../../interfaces/State';
import TeamsInterface from '../../interfaces/Teams';

import {
  ActionInterface,
  ErrorAction,
  SuccessAction,
} from '../../helpers/actionBuilder';
import { Teams, Toasts } from './';

import { nvFetch } from '../../helpers/nvFetch';

/**
 * Empty downloaded teams list
 */
export const clearTeams = (): ActionInterface => {
  return new SuccessAction(Teams.clear).json;
};

/**
 * Get a page of teams
 */
export const fetchTeams = (
  page: number = 1,
  pageSize: number = 20,
  includeDisabled: boolean = true,
  // tslint:disable-next-line no-any
): any => {
  return (
    dispatch: (action: ActionInterface) => void,
    getState: () => State,
  ) => {
    dispatch(new SuccessAction(Teams.fetching).json);

    nvFetch(
      `customers?pageSize=${pageSize}&page=${page}` +
        `&includeDisabled=${includeDisabled}`,
      getState().user.accessToken,
    )
      .then((teams: TeamsInterface) => {
        dispatch(new SuccessAction(Teams.receive, teams).json);

        dispatch(new SuccessAction(Teams.fetchingDone).json);
      })
      .catch((error: Error) => {
        dispatch(new ErrorAction(Teams.fetchingDone, error).json);

        dispatch(
          new SuccessAction(Toasts.push, {
            delay: 10000,
            message: `There was a problem fetching teams: ${error.message}`,
            type: 'negative',
          }).json,
        );
      });
  };
};
