import { contrast } from 'chroma-js';

/**
 * Picks the best text colour to use for a given hex.
 * White tends to be more visually appealing if the contrasts
 * of both white and black are close, so this is weighted
 * slightly more towards white than black.
 *
 * ...
 *
 * Please don't read too much into this
 */
export default (hex: string) => {
  const whiteColour = '#ffffff';
  const blackColour = '#4d4d4f';

  const whiteContrast = contrast(hex, whiteColour);
  const blackContrast = contrast(hex, blackColour);

  // Established using trial and error to achieve desired aesthetic
  const whitePreference = 1.5;

  // Apply the offset and compare contrasts
  const useWhite = whiteContrast + whitePreference > blackContrast;

  return useWhite ? whiteColour : blackColour;
};
