import {
  ActionInterface,
  ErrorAction,
  SuccessAction,
} from '../../helpers/actionBuilder';
import { Toasts, UserMessages } from './';

import { lcFetchAllPages } from '../../helpers/lcFetch';

import UserMessage from '../../interfaces/Chat/UserMessage';
import UserMessagesResponse from '../../interfaces/Chat/UserMessagesResponse';
import State from '../../interfaces/State';

/**
 * Empty downloaded user messages list
 */
export const clearUserMessages = (): ActionInterface => {
  return new SuccessAction(UserMessages.clear).json;
};

/**
 * Get user messages
 */
export const fetchUserMessages = (
  team: string = '',
  memberId: string = '',
  // tslint:disable-next-line no-any
): any => {
  return (
    dispatch: (action: ActionInterface) => void,
    getState: () => State,
  ) => {
    dispatch(new SuccessAction(UserMessages.fetching).json);

    // TODO customise pageSize (limit)
    lcFetchAllPages(
      `teams/${team}/members/${memberId}/messages`,
      getState().user.accessToken,
      'GET',
      {},
      {},
      5,
      true,
    )
      .then((messages: UserMessagesResponse) => {
        dispatch(new SuccessAction(UserMessages.received, messages).json);

        dispatch(new SuccessAction(UserMessages.fetchingDone).json);
      })
      .catch((error: Error) => {
        dispatch(new ErrorAction(UserMessages.fetchingDone, error).json);

        dispatch(
          new SuccessAction(Toasts.push, {
            delay: 10000,
            message: `There was a problem fetching DMs: ${error.message}`,
            type: 'negative',
          }).json,
        );
      });
  };
};

/**
 * Receive a user message
 */
export const userMessageReceived = (
  message: UserMessage,
  showNotification: boolean = false,
): ActionInterface => {
  return new SuccessAction(UserMessages.received, {
    items: [message],
  }).json;
};

/**
 * Specific user message update
 */
export const userMessageUpdated = (message: UserMessage): ActionInterface => {
  return new SuccessAction(UserMessages.updated, message).json;
};

/**
 * Remove specific user message
 */
export const userMessageDeleted = (message: UserMessage): ActionInterface => {
  return new SuccessAction(UserMessages.deleted, message).json;
};

/**
 * A map to store user typing states keyed on user id.
 * Value is a timeout which dispatches a
 * userStoppedTyping event on completion
 */
const typingMap = new Map();

/**
 * Mark userMessage as typing
 */
export const userMessagesUserTyping = (data: {
  dmid: string;
  user: string;
  finished?: boolean;
  // tslint:disable-next-line no-any
}): any => {
  const { user, dmid, finished } = data;

  return (dispatch: (action: ActionInterface) => void) => {
    if (finished) {
      dispatch(
        new SuccessAction(UserMessages.conversation.userStoppedTyping, {
          dmid,
          user,
        }).json,
      );
      return;
    }

    dispatch(
      new SuccessAction(UserMessages.conversation.userStartedTyping, {
        dmid,
        user,
      }).json,
    );

    const identifier = `${user}`;

    // Find and clear previous stopTyping timeout
    const prevTimeout = typingMap.get(identifier);
    if (prevTimeout) {
      clearTimeout(prevTimeout);
    }

    // Stop typing dispatcher
    const stopTyping = () => {
      dispatch(
        new SuccessAction(UserMessages.conversation.userStoppedTyping, {
          dmid,
          user,
        }).json,
      );
    };

    // Create and set a timeout in the map to clear typing after 3 seconds
    const timeout = setTimeout(stopTyping, 3000);
    typingMap.set(identifier, timeout);
  };
};
