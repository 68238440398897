import * as React from 'react';

import { Props, State } from './interfaces';

export default (
  // tslint:disable-next-line no-any
  importComponent: () => Promise<any>,
) =>
  class AsyncComponent extends React.Component<Props, State> {
    constructor(props: Props) {
      super(props);

      this.state = {
        component: undefined,
      };
    }

    public async componentDidMount(): Promise<void> {
      const asyncComponent = await importComponent();
      const component = asyncComponent.default;

      this.setState({
        component,
      });
    }

    public render(): JSX.Element {
      const Component = this.state.component;

      return Component ? <Component {...this.props} /> : null;
    }
  };
