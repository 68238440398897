/**
 * Remove duplicate object from array based on key provided
 *
 * @param array Array to remove duplicate
 * @param key Key to find matches on. Default `id`
 * @return any[] Deduplicated array
 */
export const deduplicate = (
  array: any[], // tslint:disable-line no-any
  key: string = 'id',
  // tslint:disable-next-line no-any
): any[] => {
  // Fuck this ugly code
  const deduped = array.filter(
    (
      item: any, // tslint:disable-line no-any
      index: number,
      self: any[], // tslint:disable-line no-any
    ) => self.findIndex((i) => i[key] === item[key]) === index,
  );

  return deduped;
};

export default deduplicate;
