import Invite from '../interfaces/Invite';

const { partnerID } = require('../partner.json');

interface CustomHeaders extends Partial<Headers> {
  Accept?: string;
  'Access-Control-Allow-Origin'?: string;
  Authorization?: string;
  'Content-Type'?: string;
  'X-Partner-Id'?: string;
  'X-WWW-Authenticate'?: string;
}

const doFetch = (
  resource = '',
  options = {},
  // tslint:disable-next-line no-any
  resolve: (data: any) => void,
  reject: (error: Error) => void,
): void => {
  fetch(resource, options)
    .then((response: Response) => {
      if (response.status < 400) {
        return response.json();
      }

      return response
        .json()
        .then(reject)
        .catch(() => {
          reject(new Error(`${response.status}: ${response.statusText}`));
        });
    })
    .then(resolve)
    .catch(reject);
};

/**
 * Fetch something from Nimvelo API
 * Provides all necessary headers
 *
 * @param resource E.g. 'customers/5'
 * @param accessToken Encoded JWT
 * @param method POST, GET, PUT, DELETE
 * @param body JSON data. Ignored unless method is PUT or POST
 * @param customHeaders Additional request headers
 * @return Promise
 */
export const nvFetch = (
  resource: string,
  accessToken?: string,
  method: 'POST' | 'GET' | 'PUT' | 'DELETE' = 'GET',
  body?: any, // tslint:disable-line no-any
  customHeaders?: CustomHeaders,
  // tslint:disable-next-line no-any
): Promise<any> =>
  new Promise((resolve: () => void, reject: (error: Error) => void) => {
    const APIRoot = process.env.REACT_APP_PBX_API;

    // TODO retype this
    // tslint:disable-next-line no-any
    const options: any = { method };
    // const options: Partial<Request> = { method };

    const headers: CustomHeaders = {
      Accept: 'application/json',
      'Access-Control-Allow-Origin': 'origin',
      'Content-Type': 'application/json',
      'X-Partner-Id': partnerID,
      'X-WWW-Authenticate': 'false',
      ...customHeaders,
    };

    if (accessToken) {
      headers.Authorization = `Bearer ${accessToken}`;
    }

    // Unset headers which are passed as undefined
    for (const key in customHeaders) {
      if (customHeaders[key] === undefined) {
        delete headers[key];
      }
    }

    options.headers = headers;

    if (method === 'PUT' || method === 'POST') {
      if (body) {
        options.body = body;

        if (!(body instanceof FormData)) {
          try {
            options.body = JSON.stringify(body);
          } catch (error) {
            // ¯\_(ツ)_/¯
          }
        }
      } else {
        reject(new Error('No body data'));
      }
    }

    const fullResource = `${APIRoot}/${resource}`;

    doFetch(fullResource, options, resolve, reject);
  });

/**
 * Send an invite for someone to join a team
 *
 * @param email Address to send invitation to
 * @param teamId Which team should they be invited to
 * @param accessToken Encoded JWT
 * @return Promise
 */
export const inviteTeammember = (
  email: string,
  teamId: string,
  accessToken: string,
) =>
  nvFetch(`customers/${teamId}/linkedusers`, accessToken, 'POST', {
    accessLevel: 'EXTENSION',
    activateUrl: location.origin + '/acceptinvite',
    email,
    enabled: true,
    owner: false,
    pending: false,
    recordingAccess: 'NONE',
    type: 'linkeduser',
  });

/**
 * Download an invite object
 *
 * @param inviteId ID of invite object
 * @return Promise
 */
export const getInvite = (inviteId: string = ''): Promise<Invite> =>
  nvFetch(`invites/${inviteId}`);

/**
 * Upload an invite object
 *
 * @param invite invite object
 * @return Promise
 */
export const setInvite = (
  invite: Invite,
  accessToken?: string,
): Promise<Invite> =>
  nvFetch(`invites/${invite.id}`, accessToken, 'PUT', invite);
