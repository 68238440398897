import * as React from 'react';

import { ConnectedRouter } from 'connected-react-router';
import { Route, Switch } from 'react-router-dom';

import AsyncComponent from '../AsyncComponent';
import Toast from '../Toast';

const App = AsyncComponent(() => import('../App'));
const Login = AsyncComponent(() => import('../Login'));

const {
  devIntercomID,
  intercomID,
  intercomVerifierUrl,
} = require('../../partner.json');

import { IS_PROD } from '../../helpers/constants';
import { history } from '../../helpers/history';
import Intercom from '../../helpers/intercom';

import { Props, State } from './interfaces';

import styles from './style.module.scss';

// if (!IS_PROD) {
//   const { whyDidYouUpdate } = require('why-did-you-update');
//   whyDidYouUpdate(
//     React,
//     {
//       exclude: /^(FontAwesome|Connect|Route|BrowserRouter|Switch|Link|Entry)/,
//     },
//   );
// }

export default class Root extends React.Component<Props, State> {
  // public componentDidMount (): void {
  //   if (!IS_PROD) {
  //     this.props.addToast({
  //       delay: -1, // Does not auto dismiss
  //       message: 'Development environment',
  //       type: 'development',
  //     });
  //   }
  // }

  public readonly state: State = {
    intercom: new Intercom(
      IS_PROD ? intercomID : devIntercomID,
      undefined,
      true,
      '#help-button',
    ),
    intercomUserHash: '',
    userId: '', // root user id (i.e. /users/me, not linkeduser id)
  };

  public async componentDidMount(): Promise<void> {
    const theme = localStorage.getItem('nv-snowdon-theme');

    if (theme) {
      this.props.setTheme(theme);
    }

    this.getUserDetails(this.props.user);
  }

  public async componentDidUpdate(prevProps: Props): Promise<void> {
    if (prevProps.user.id !== this.props.user.id) {
      this.getUserDetails(this.props.user);
    }

    if (this.props.user.id) {
      this.state.intercom.companyId = this.props.team.id;
      this.state.intercom.companyCreatedAt = this.props.team.created;
      this.state.intercom.companyName = this.props.team.company;
      this.state.intercom.createdAt = this.props.user.created;
      this.state.intercom.email = this.props.user.email;
      this.state.intercom.name = this.props.user.displayName;
      this.state.intercom.userHash = this.state.intercomUserHash;
      this.state.intercom.userId = this.props.user.userId;
    }
  }

  public render(): JSX.Element {
    const toasts = this.props.toasts;

    let toastNode: JSX.Element;

    if (toasts.length > 0) {
      const toast = toasts[0];
      toastNode = (
        <Toast
          buttons={toast.buttons}
          delay={toast.delay}
          id={toast.id}
          message={toast.message}
          removing={toast.removing}
          shift={this.props.shiftToast}
          type={toast.type}
        />
      );
    }

    return (
      <div className={styles.container}>
        <ConnectedRouter history={history}>
          <Switch>
            <Route exact path='/login' component={Login} />
            <Route path='*' component={App} />
          </Switch>
        </ConnectedRouter>
        {toastNode}
      </div>
    );
  }

  private readonly getUserDetails = async (
    user = this.props.user,
  ): Promise<void> => {
    if (!user.id) {
      // No user in store yet, not point verifiying them
      return;
    }

    if (IS_PROD && intercomVerifierUrl) {
      let verifierResponse: {
        user_hash: string;
        user_id: string;
      };

      try {
        const response = await fetch(intercomVerifierUrl, {
          headers: {
            Authorization: 'Bearer ' + user.accessToken,
            'Content-Type': 'application/json',
          },
        });

        verifierResponse = await response.json();
      } catch (error) {
        verifierResponse = {
          user_hash: '',
          user_id: '',
        };
      }

      this.setState({
        intercomUserHash: verifierResponse.user_hash,
        userId: verifierResponse.user_id,
      });
    } else {
      this.setState({
        userId: user.userId,
      });
    }
  };
}
