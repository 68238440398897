import { routerMiddleware } from 'connected-react-router';
import { throttle } from 'lodash';
import createRavenMiddleware from 'raven-for-redux';
import Raven from 'raven-js';
import { applyMiddleware, compose, createStore, Middleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import { history } from '../helpers/history';
import { dynamicMiddlewares } from './dynamicMiddlewares';

import createRootReducer from './reducers';
import { loadState, saveState } from './statePersistence';

import { DSN, IS_PROD } from '../helpers/constants';

import State from '../interfaces/State';

Raven.config(DSN, {
  debug: !IS_PROD,
  environment: IS_PROD ? 'production' : 'development',
  release: process.env.REACT_APP_VERSION,
  tags: {
    git_commit: process.env.REACT_APP_COMMIT,
  },
}).install();

export const configureStore = () => {
  const initialState: Partial<State> = loadState();

  const middleware: Middleware[] = [
    createRavenMiddleware(Raven, {}),
    dynamicMiddlewares,
    routerMiddleware(history),
    thunk,
  ];

  let composedMiddleware;

  if (!IS_PROD) {
    composedMiddleware = composeWithDevTools(applyMiddleware(...middleware));
  } else {
    composedMiddleware = compose(applyMiddleware(...middleware));
  }

  const store = createStore(
    createRootReducer(history),
    initialState,
    composedMiddleware,
  );

  // Don't allow state to be stored more than once a second
  store.subscribe(
    throttle(() => {
      saveState(store.getState() as State);
    }, 1000),
  );

  return store;
};
