import Conversation from '../../interfaces/Chat/Conversation';
import ConversationsResponse from '../../interfaces/Chat/ConversationsResponse';
import Message from '../../interfaces/Chat/Message';
import MessagesResponse from '../../interfaces/Chat/MessagesResponse';
import State from '../../interfaces/State';

import {
  ActionInterface,
  ErrorAction,
  SuccessAction,
} from '../../helpers/actionBuilder';
import { lcFetch, lcFetchAllPages } from '../../helpers/lcFetch';

import { Conversations, Toasts } from './';

/**
 * Empty downloaded conversations list
 */
export const clearConversations = (): ActionInterface => {
  return new SuccessAction(Conversations.clear).json;
};

/**
 * Get all conversations
 */
// tslint:disable-next-line no-any
export const fetchConversations = (teamId: string = ''): any => {
  return (
    dispatch: (action: ActionInterface) => void,
    getState: () => State,
  ) => {
    dispatch(new SuccessAction(Conversations.fetching).json);

    lcFetchAllPages(
      `teams/${teamId}/conversations`,
      getState().user.accessToken,
      'GET',
      undefined,
      undefined,
      3,
    )
      .then((conversations: Conversation[]) => {
        dispatch(new SuccessAction(Conversations.receive, conversations).json);

        dispatch(new SuccessAction(Conversations.fetchingDone).json);
      })
      .catch((error: Error) => {
        dispatch(new ErrorAction(Conversations.fetchingDone, error).json);

        dispatch(
          new SuccessAction(Toasts.push, {
            delay: 10000,
            message:
              "There was a problem fetching this team's conversations: " +
              error.message,
            type: 'negative',
          }).json,
        );
      });
  };
};

/**
 * Get a page of conversations with participants
 */
export const fetchConversationsWithParticipants = (
  teamId: string = '',
  participants: string[] = [],
  // tslint:disable-next-line no-any
): any => {
  return (
    dispatch: (action: ActionInterface) => void,
    getState: () => State,
  ) => {
    dispatch(new SuccessAction(Conversations.fetching).json);

    lcFetch(
      `teams/${teamId}/conversations?participants=${participants.join(',')}`,
      getState().user.accessToken,
    )
      .then((conversations: ConversationsResponse) => {
        dispatch(new SuccessAction(Conversations.receive, conversations).json);

        dispatch(new SuccessAction(Conversations.fetchingDone).json);
      })
      .catch((error: Error) => {
        dispatch(new ErrorAction(Conversations.fetchingDone, error).json);

        dispatch(
          new SuccessAction(Toasts.push, {
            delay: 10000,
            message:
              "There was a problem fetching this team's conversations: " +
              error.message,
            type: 'negative',
          }).json,
        );
      });
  };
};

/**
 * Get a page of conversations with tags
 */
export const fetchConversationsWithTags = (
  teamId: string = '',
  tags: string[] = [],
  // tslint:disable-next-line no-any
): any => {
  return (
    dispatch: (action: ActionInterface) => void,
    getState: () => State,
  ) => {
    dispatch(new SuccessAction(Conversations.fetching).json);

    lcFetch(
      `teams/${teamId}/conversations?tags=${tags.join(',')}`,
      getState().user.accessToken,
    )
      .then((conversations: ConversationsResponse) => {
        dispatch(new SuccessAction(Conversations.receive, conversations).json);

        dispatch(new SuccessAction(Conversations.fetchingDone).json);
      })
      .catch((error: Error) => {
        dispatch(new ErrorAction(Conversations.fetchingDone, error).json);

        dispatch(
          new SuccessAction(Toasts.push, {
            delay: 10000,
            message:
              "There was a problem fetching this team's conversations: " +
              error.message,
            type: 'negative',
          }).json,
        );
      });
  };
};

/**
 * Get a page of inbox messages
 */
export const fetchInboxMessages = (
  team: string = '',
  pageSize: number = 50, // TODO Implement pagination
  // tslint:disable-next-line no-any
): any => {
  return (
    dispatch: (action: ActionInterface) => void,
    getState: () => State,
  ) => {
    dispatch(new SuccessAction(Conversations.inbox.fetching).json);

    lcFetchAllPages(
      `teams/${team}/conversations/inbox/messages`,
      getState().user.accessToken,
    )
      .then((messages: MessagesResponse) => {
        dispatch(
          new SuccessAction(Conversations.inbox.received, messages).json,
        );

        dispatch(new SuccessAction(Conversations.inbox.fetchingDone).json);
      })
      .catch((error: Error) => {
        dispatch(new ErrorAction(Conversations.inbox.fetchingDone, error).json);

        dispatch(
          new SuccessAction(Toasts.push, {
            delay: 10000,
            message: `There was a problem fetching the Inbox: ${error.message}`,
            type: 'negative',
          }).json,
        );
      });
  };
};

/**
 * Get a page of untagged conversations
 */
// tslint:disable-next-line no-any
export const fetchUntaggedConversations = (teamId: string = ''): any => {
  return (
    dispatch: (action: ActionInterface) => void,
    getState: () => State,
  ) => {
    dispatch(new SuccessAction(Conversations.fetching).json);

    lcFetch(
      `teams/${teamId}/conversations/untagged`,
      getState().user.accessToken,
    )
      .then((conversations: ConversationsResponse) => {
        dispatch(new SuccessAction(Conversations.receive, conversations).json);

        dispatch(new SuccessAction(Conversations.fetchingDone).json);
      })
      .catch((error: Error) => {
        dispatch(new ErrorAction(Conversations.fetchingDone, error).json);

        dispatch(
          new SuccessAction(Toasts.push, {
            delay: 10000,
            message:
              "There was a problem fetching this team's conversations: " +
              error.message,
            type: 'negative',
          }).json,
        );
      });
  };
};

/**
 * Get a page of archived conversations
 */
// tslint:disable-next-line no-any
export const fetchArchivedConversations = (teamId: string = ''): any => {
  return (
    dispatch: (action: ActionInterface) => void,
    getState: () => State,
  ) => {
    dispatch(new SuccessAction(Conversations.fetching).json);

    lcFetch(
      `teams/${teamId}/conversations/archived`,
      getState().user.accessToken,
    )
      .then((conversations: ConversationsResponse) => {
        dispatch(new SuccessAction(Conversations.receive, conversations).json);

        dispatch(new SuccessAction(Conversations.fetchingDone).json);
      })
      .catch((error: Error) => {
        dispatch(new ErrorAction(Conversations.fetchingDone, error).json);

        dispatch(
          new SuccessAction(Toasts.push, {
            delay: 10000,
            message:
              "There was a problem fetching this team's conversations: " +
              error.message,
            type: 'negative',
          }).json,
        );
      });
  };
};

/**
 * Receive a conversation object (e.g. 'conversations:update' socket event)
 */
export const receiveConversation = (
  conversation: Conversation,
): ActionInterface => {
  return new SuccessAction(Conversations.receive, {
    items: [conversation],
  }).json;
};

/**
 * TODO write jsdoc
 */
export const recalculateUnread = (conversationId: string): ActionInterface => {
  return new SuccessAction(
    Conversations.conversation.recalculateUnread,
    conversationId,
  ).json;
};

/**
 * Get a specific conversation
 */
export const fetchConversation = (
  teamId: string = '',
  conversationId: string = '',
  // tslint:disable-next-line no-any
): any => {
  return (
    dispatch: (action: ActionInterface) => void,
    getState: () => State,
  ) => {
    dispatch(new SuccessAction(Conversations.conversation.fetching).json);

    lcFetch(
      `teams/${teamId}/conversations/${conversationId}`,
      getState().user.accessToken,
    )
      .then((conversation: Conversation) => {
        dispatch(
          new SuccessAction(Conversations.conversation.received, {
            items: [conversation],
          }).json,
        );

        dispatch(
          new SuccessAction(Conversations.conversation.fetchingDone).json,
        );
      })
      .catch((error: Error) => {
        dispatch(
          new ErrorAction(Conversations.conversation.fetchingDone, error).json,
        );

        dispatch(
          new SuccessAction(Toasts.push, {
            delay: 10000,
            message:
              'There was a problem fetching this conversation: ' +
              error.message,
            type: 'negative',
          }).json,
        );
      });
  };
};

/**
 * Get a specific conversation's messages
 */
export const fetchConversationMessages = (
  team: string = '',
  conversationId: string = '',
  params: {
    limit?: number;
    oldest?: string;
    latest?: string;
  } = {},
  // tslint:disable-next-line no-any
): any => {
  return (
    dispatch: (action: ActionInterface) => void,
    getState: () => State,
  ) => {
    dispatch(new SuccessAction(Conversations.conversation.fetching).json);

    lcFetch(
      `teams/${team}/conversations/${conversationId || 'lobby'}/messages`,
      getState().user.accessToken,
      'GET',
      {},
      {},
      {
        latest: params.latest,
        limit: params.limit || 200,
        oldest: params.oldest,
      },
    )
      .then((messages: MessagesResponse) => {
        dispatch(
          new SuccessAction(
            Conversations.conversation.messages.received,
            messages,
          ).json,
        );

        dispatch(
          new SuccessAction(Conversations.conversation.messages.fetchingDone)
            .json,
        );
      })
      .catch((error: Error) => {
        dispatch(
          new ErrorAction(
            Conversations.conversation.messages.fetchingDone,
            error,
          ).json,
        );

        dispatch(
          new SuccessAction(Toasts.push, {
            delay: 10000,
            message: `There was a problem fetching messages: ${error.message}`,
            type: 'negative',
          }).json,
        );
      });
  };
};

/*
 * Receive a message
 * TODO this needs generalising with lobby action lobbyMessageReceived
 */
export const messageReceived = (
  message: Message,
  showNotification: boolean = false,
): ActionInterface => {
  return new SuccessAction(Conversations.conversation.messages.received, {
    items: [message],
  }).json;
};

/**
 * Empty downloaded conversation
 */
export const clearConversation = (): ActionInterface => {
  return new SuccessAction(Conversations.conversation.clear).json;
};

/**
 * Specific message update
 */
export const messageUpdated = (message: Message): ActionInterface => {
  return new SuccessAction(Conversations.conversation.messages.updated, message)
    .json;
};

/**
 * Remove specific message
 */
export const messageDeleted = (message: Message): ActionInterface => {
  return new SuccessAction(Conversations.conversation.messages.deleted, message)
    .json;
};

/**
 * Conversation got deleted (e.g. 'conversations:archive' socket event)
 */
export const conversationDeleted = (
  conversation: Conversation,
): ActionInterface => {
  return new SuccessAction(Conversations.conversation.delete, conversation)
    .json;
};

/**
 * Update a conversation
 */
export const updateConversation = (
  teamId: string = '',
  conversation: Conversation,
  // tslint:disable-next-line no-any
): any => {
  return (
    dispatch: (action: ActionInterface) => void,
    getState: () => State,
  ) => {
    dispatch(new SuccessAction(Conversations.conversation.fetching).json);

    const sanitizedConversation = { ...conversation };
    const propsToDelete = [
      'fetching',
      'fetchingMessages',
      'message',
      'messages',
      'unreadMessagesCount',
      'isPrivate',
    ];
    propsToDelete.forEach((prop) => {
      delete sanitizedConversation[prop];
    });

    lcFetch(
      `teams/${teamId}/conversations/${conversation.id}`,
      getState().user.accessToken,
      'PUT',
      sanitizedConversation,
    )
      .then((updatedConversation: Conversation) => {
        dispatch(
          new SuccessAction(Conversations.receive, {
            items: [updatedConversation],
          }).json,
        );

        dispatch(
          new SuccessAction(Conversations.conversation.fetchingDone).json,
        );
      })
      .catch((error: Error) => {
        dispatch(
          new ErrorAction(Conversations.conversation.fetchingDone, error).json,
        );

        dispatch(
          new SuccessAction(Toasts.push, {
            delay: 10000,
            message:
              'There was a problem updating this conversation: ' +
              error.message,
            type: 'negative',
          }).json,
        );
      });
  };
};

/**
 * A map to store user typing states keyed on conversation id
 * and user id. Value is a timeout which dispatches a
 * userStoppedTyping event on completion
 */
const typingMap = new Map();

/**
 * Mark a conversation as typing
 */
export const conversationUserTyping = (
  conversationId: string,
  user: string,
  finished: boolean,
  // tslint:disable-next-line no-any
): any => {
  return (
    dispatch: (action: ActionInterface) => void,
    getState: () => State,
  ) => {
    if (finished) {
      dispatch(
        new SuccessAction(Conversations.conversation.userStoppedTyping, {
          conversation: conversationId,
          user,
        }).json,
      );
      return;
    }

    dispatch(
      new SuccessAction(Conversations.conversation.userStartedTyping, {
        conversation: conversationId,
        user,
      }).json,
    );

    const identifier = `${conversationId}:${user}`;

    // Find and clear previous stopTyping timeout
    const prevTimeout = typingMap.get(identifier);
    if (prevTimeout) {
      clearTimeout(prevTimeout);
    }

    // Stop typing dispatcher
    const stopTyping = () => {
      dispatch(
        new SuccessAction(Conversations.conversation.userStoppedTyping, {
          conversation: conversationId,
          user,
        }).json,
      );
    };

    // Create and set a timeout in the map to clear typing after 3 seconds
    const timeout = setTimeout(stopTyping, 3000);
    typingMap.set(identifier, timeout);
  };
};

/**
 * User joined a conversation
 */
export const userJoined = (
  conversationId: string,
  userId: string,
): ActionInterface => {
  return new SuccessAction(Conversations.conversation.userJoined, {
    conversationId,
    userId,
  }).json;
};

/**
 * User left a conversation
 */
export const userLeft = (
  conversationId: string,
  userId: string,
): ActionInterface => {
  return new SuccessAction(Conversations.conversation.userLeft, {
    conversationId,
    userId,
  }).json;
};
