import TagInterface from '../../interfaces/Chat/Tag';
import TagsResponse from '../../interfaces/Chat/TagsResponse';
import State from '../../interfaces/State';

import {
  ActionInterface,
  ErrorAction,
  SuccessAction,
} from '../../helpers/actionBuilder';
import { Tags, Toasts } from './';

import { lcFetch } from '../../helpers/lcFetch';

/**
 * Empty downloaded tags list
 */
export const clearTags = (): ActionInterface => {
  return new SuccessAction(Tags.clear).json;
};

/**
 * Get a page of tags
 */
// tslint:disable-next-line no-any
export const fetchTag = (teamId: string = '', tagId: string = ''): any => {
  return (
    dispatch: (action: ActionInterface) => void,
    getState: () => State,
  ) => {
    dispatch(new SuccessAction(Tags.fetching).json);

    lcFetch(`teams/${teamId}/tags/${tagId}`, getState().user.accessToken)
      .then((tag: TagInterface) => {
        dispatch(
          new SuccessAction(Tags.receive, {
            items: [tag],
          }).json,
        );

        dispatch(new SuccessAction(Tags.fetchingDone).json);
      })
      .catch((error: Error) => {
        dispatch(new ErrorAction(Tags.fetchingDone, error).json);

        dispatch(
          new SuccessAction(Toasts.push, {
            delay: 10000,
            message:
              "There was a problem fetching this team's tags: " + error.message,
            type: 'negative',
          }).json,
        );
      });
  };
};

/**
 * Get a tags
 */
// tslint:disable-next-line no-any
export const fetchTags = (teamId: string = ''): any => {
  return (
    dispatch: (action: ActionInterface) => void,
    getState: () => State,
  ) => {
    dispatch(new SuccessAction(Tags.fetching).json);

    lcFetch(`teams/${teamId}/tags`, getState().user.accessToken)
      .then((tags: TagsResponse) => {
        dispatch(new SuccessAction(Tags.receive, tags).json);

        dispatch(new SuccessAction(Tags.fetchingDone).json);
      })
      .catch((error: Error) => {
        dispatch(new ErrorAction(Tags.fetchingDone, error).json);

        dispatch(
          new SuccessAction(Toasts.push, {
            delay: 10000,
            message:
              "There was a problem fetching this team's tags: " + error.message,
            type: 'negative',
          }).json,
        );
      });
  };
};

/**
 * Update a tag
 */
// tslint:disable-next-line no-any
export const updateTag = (teamId: string = '', tag: TagInterface): any => {
  return (
    dispatch: (action: ActionInterface) => void,
    getState: () => State,
  ) => {
    dispatch(new SuccessAction(Tags.fetching).json);

    lcFetch(
      `teams/${teamId}/tags/${tag.id}`,
      getState().user.accessToken,
      'PUT',
      tag,
    )
      .then((tag: TagInterface) => {
        dispatch(
          new SuccessAction(Tags.receive, {
            items: [tag],
          }).json,
        );

        dispatch(new SuccessAction(Tags.fetchingDone).json);
      })
      .catch((error: Error) => {
        dispatch(new ErrorAction(Tags.fetchingDone, error).json);

        dispatch(
          new SuccessAction(Toasts.push, {
            delay: 10000,
            message: 'There was a problem updating this tag: ' + error.message,
            type: 'negative',
          }).json,
        );
      });
  };
};

/**
 * Create a tag
 */
export const createTag = (
  teamId: string = '',
  tag: {
    colour: string;
    icon: string;
    name: string;
  },
  // tslint:disable-next-line no-any
): any => {
  return (
    dispatch: (action: ActionInterface) => void,
    getState: () => State,
  ) => {
    dispatch(new SuccessAction(Tags.fetching).json);

    lcFetch(`teams/${teamId}/tags`, getState().user.accessToken, 'POST', tag)
      .then((tag: TagInterface) => {
        dispatch(
          new SuccessAction(Tags.receive, {
            items: [tag],
          }).json,
        );

        dispatch(new SuccessAction(Tags.fetchingDone).json);
      })
      .catch((error: Error) => {
        dispatch(new ErrorAction(Tags.fetchingDone, error).json);

        dispatch(
          new SuccessAction(Toasts.push, {
            delay: 10000,
            message: 'There was a problem creating this tag: ' + error.message,
            type: 'negative',
          }).json,
        );
      });
  };
};

/**
 * Delete a tag
 */
// tslint:disable-next-line no-any
export const deleteTag = (teamId: string, tag: TagInterface): any => {
  return (
    dispatch: (action: ActionInterface) => void,
    getState: () => State,
  ) => {
    dispatch(new SuccessAction(Tags.fetching).json);

    lcFetch(
      `teams/${teamId}/tags/${tag.id}`,
      getState().user.accessToken,
      'DELETE',
    ).catch((error: Error) => {
      dispatch(new ErrorAction(Tags.fetchingDone, error).json);

      dispatch(
        new SuccessAction(Toasts.push, {
          delay: 10000,
          message: 'There was a problem deleting this tag: ' + error.message,
          type: 'negative',
        }).json,
      );
    });
  };
};

/**
 * Receive a tag object (e.g. 'tags:update' socket event)
 */
// tslint:disable-next-line no-any
export const receiveTag = (tag: TagInterface): any => {
  return (dispatch: (action: ActionInterface) => void) => {
    dispatch(
      new SuccessAction(Tags.receive, {
        items: [tag],
      }).json,
    );
  };
};

/**
 * Dlete a tag object (e.g. 'tags:delete' socket event)
 */
export const clearTag = (tag: TagInterface): ActionInterface => {
  return new SuccessAction(Tags.tag.clear, tag).json;
};
