import { push } from 'connected-react-router';

import State from '../../interfaces/State';

import { ActionInterface, SuccessAction } from '../../helpers/actionBuilder';

import { Conversations } from './';

import { lcFetch } from '../../helpers/lcFetch';

/**
 * Link to a specific message
 */
export const linkToMessage = (
  teamId: string = '',
  messageId: string = '',
  // tslint:disable-next-line no-any
): any => {
  return async (
    dispatch: (action: ActionInterface) => void,
    getState: () => State,
  ) => {
    const messages = getState().conversations.items.reduce(
      (a, c) => [...a, ...c.messages],
      [],
    );

    // Do we already have the message in the store?
    let message = messages.find((x) => x.id === messageId);
    let isLobby = false;

    if (!message) {
      // We don't have the message, let's fetch it
      message = await lcFetch(
        `teams/${teamId}/messages/${messageId}`,
        getState().user.accessToken,
      );
    }

    let conversationId = message.conversation;

    if (conversationId) {
      let conversation = getState().conversations.items.find(
        (x) => x.id === conversationId,
      );

      if (!conversation) {
        conversation = await lcFetch(
          `teams/${teamId}/conversations/${conversationId}`,
          getState().user.accessToken,
        );

        dispatch(new SuccessAction(Conversations.receive, [conversation]).json);
      }
    } else {
      conversationId = 'lobby';
      isLobby = true;
    }

    const prevMessages = await lcFetch(
      `teams/${teamId}/conversations/${conversationId}/messages`,
      getState().user.accessToken,
      'GET',
      {},
      {},
      {
        latest: message.posted,
      },
    );

    const nextMessages = await lcFetch(
      `teams/${teamId}/conversations/${conversationId}/messages`,
      getState().user.accessToken,
      'GET',
      {},
      {},
      {
        oldest: message.posted,
      },
    );

    const messagesArr = [...prevMessages.items, message, ...nextMessages.items];

    dispatch(
      new SuccessAction(Conversations.conversation.messages.received, {
        items: messagesArr,
      }).json,
    );

    const url = isLobby
      ? `/teams/${teamId}/lobby?messageId=${message.id}`
      : `/teams/${teamId}/conversations/${conversationId}?messageId=${
          message.id
        }`;

    dispatch(push(url) as ActionInterface);
  };
};
