import TaskInterface from '../../interfaces/Chat/Task';
import TasksResponse from '../../interfaces/Chat/TasksResponse';
import State from '../../interfaces/State';

import {
  ActionInterface,
  ErrorAction,
  SuccessAction,
} from '../../helpers/actionBuilder';
import { lcFetch } from '../../helpers/lcFetch';

import { Tasks, Toasts } from './';

/**
 * Empty downloaded tasks list
 */
export const clearTasks = (): ActionInterface => {
  return new SuccessAction(Tasks.clear).json;
};

/**
 * Get a page of tasks
 */
// tslint:disable-next-line no-any
export const fetchTask = (teamId: string = '', taskId: string = ''): any => {
  return (
    dispatch: (action: ActionInterface) => void,
    getState: () => State,
  ) => {
    dispatch(new SuccessAction(Tasks.fetching).json);

    lcFetch(`teams/${teamId}/tasks/${taskId}`, getState().user.accessToken)
      .then((task: TaskInterface) => {
        dispatch(
          new SuccessAction(Tasks.receive, {
            items: [task],
          }).json,
        );

        dispatch(new SuccessAction(Tasks.fetchingDone).json);
      })
      .catch((error: Error) => {
        dispatch(new ErrorAction(Tasks.fetchingDone, error).json);

        dispatch(
          new SuccessAction(Toasts.push, {
            delay: 10000,
            message:
              "There was a problem fetching this team's tasks: " +
              error.message,
            type: 'negative',
          }).json,
        );
      });
  };
};

/**
 * Get a tasks
 */
export const fetchTasks = (
  teamId: string = '',
  conversationId: string = '',
  // tslint:disable-next-line no-any
): any => {
  return (
    dispatch: (action: ActionInterface) => void,
    getState: () => State,
  ) => {
    dispatch(new SuccessAction(Tasks.fetching).json);

    const url = conversationId
      ? `teams/${teamId}/conversations/${conversationId}/tasks`
      : `teams/${teamId}/tasks`;

    lcFetch(url, getState().user.accessToken)
      .then((tasks: TasksResponse) => {
        dispatch(new SuccessAction(Tasks.receive, tasks).json);

        dispatch(new SuccessAction(Tasks.fetchingDone).json);
      })
      .catch((error: Error) => {
        dispatch(new ErrorAction(Tasks.fetchingDone, error).json);

        dispatch(
          new SuccessAction(Toasts.push, {
            delay: 10000,
            message:
              "There was a problem fetching this team's tasks: " +
              error.message,
            type: 'negative',
          }).json,
        );
      });
  };
};

/**
 * Update a task
 */
// tslint:disable-next-line no-any
export const updateTask = (teamId: string = '', task: TaskInterface): any => {
  return (
    dispatch: (action: ActionInterface) => void,
    getState: () => State,
  ) => {
    dispatch(
      new SuccessAction(Tasks.receive, {
        items: [task],
      }).json,
    );

    lcFetch(
      `teams/${teamId}/tasks/${task.id}`,
      getState().user.accessToken,
      'PUT',
      task,
    ).catch((error: Error) => {
      dispatch(new ErrorAction(Tasks.fetchingDone, error).json);

      dispatch(
        new SuccessAction(Toasts.push, {
          delay: 10000,
          message: 'There was a problem updating this task: ' + error.message,
          type: 'negative',
        }).json,
      );
    });
  };
};

/**
 * Create a task
 */
export const createTask = (
  teamId: string = '',
  task: { title: string },
  // tslint:disable-next-line no-any
): any => {
  return (
    dispatch: (action: ActionInterface) => void,
    getState: () => State,
  ) => {
    dispatch(
      new SuccessAction(Tasks.receive, {
        items: [task],
      }).json,
    );

    lcFetch(
      `teams/${teamId}/tasks`,
      getState().user.accessToken,
      'POST',
      task,
    ).catch((error: Error) => {
      dispatch(new ErrorAction(Tasks.fetchingDone, error).json);

      dispatch(
        new SuccessAction(Toasts.push, {
          delay: 10000,
          message: 'There was a problem creating this task: ' + error.message,
          type: 'negative',
        }).json,
      );
    });
  };
};

/**
 * Delete a task
 */
// tslint:disable-next-line no-any
export const deleteTask = (teamId: string, task: TaskInterface): any => {
  return (
    dispatch: (action: ActionInterface) => void,
    getState: () => State,
  ) => {
    dispatch(new SuccessAction(Tasks.task.clear, task).json);

    lcFetch(
      `teams/${teamId}/tasks/${task.id}`,
      getState().user.accessToken,
      'DELETE',
    ).catch((error: Error) => {
      dispatch(new ErrorAction(Tasks.fetchingDone, error).json);

      dispatch(
        new SuccessAction(Toasts.push, {
          delay: 10000,
          message: 'There was a problem deleting this task: ' + error.message,
          type: 'negative',
        }).json,
      );
    });
  };
};

/**
 * Receive a task object (e.g. 'tasks:update' socket event)
 */
export const receiveTask = (task: TaskInterface): ActionInterface => {
  return new SuccessAction(Tasks.receive, {
    items: [task],
  }).json;
};

/**
 * Delete a task object (e.g. 'tasks:delete' socket event)
 */
export const clearTask = (task: TaskInterface): ActionInterface => {
  return new SuccessAction(Tasks.task.clear, task).json;
};
