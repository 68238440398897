import IntercomScript from './intercomScript';

const verbose = false;

interface KeeroIntercomUser {
  company?: {
    company_id?: string;
    created_at?: Date;
    name?: string;
  };
  created_at?: Date;
  email?: string;
  keero_last_seen?: Date;
  name?: string;
  user_hash?: string;
  user_id?: string;
}

interface IntercomConfig extends KeeroIntercomUser {
  app_id: string;
  hide_default_launcher?: boolean;
  custom_launcher_selector?: string;
}

export default class Intercom {
  private Intercom: (method: string, data?: object) => void;

  private updateThrottle: number = 500; // ms
  private updateTimeout: NodeJS.Timer;

  private _appId: string;
  private _companyId: string;
  private _companyCreatedAt: Date;
  private _companyName: string;
  private _createdAt: Date;
  private _email: string;
  private _name: string;
  private _userHash: string;
  private _userId: string;

  private hideDefaultLauncher: boolean;
  private customLauncherSelector: string;

  constructor(
    appId: string,
    user?: KeeroIntercomUser,
    hideDefaultLauncher?: boolean,
    customLauncherSelector?: string,
  ) {
    this._appId = appId;

    if (user) {
      if (user.company) {
        if (user.company.company_id) {
          this.companyId = user.company.company_id;
        }
        if (user.company.created_at) {
          this._companyCreatedAt = new Date(user.company.created_at);
        }
        if (user.company.name) {
          this._companyName = user.company.name;
        }
      }

      if (user.created_at) {
        this._createdAt = new Date(user.created_at);
      }
      if (user.email) {
        this._email = user.email;
      }
      if (user.name) {
        this._name = user.name;
      }
      if (user.user_hash) {
        this._userHash = user.user_hash;
      }
      if (user.user_id) {
        this._userId = user.user_id;
      }
    }

    if (hideDefaultLauncher) {
      this.hideDefaultLauncher = hideDefaultLauncher;
    }

    if (customLauncherSelector) {
      this.customLauncherSelector = customLauncherSelector;
    }

    IntercomScript(this._appId);

    // tslint:disable-next-line no-any
    this.Intercom = (window as any).Intercom;

    if (verbose) {
      console.log('Booting Intercom with data:');
      console.log(this.intercomConfig);
    }

    // tslint:disable-next-line no-any
    if (!(this.Intercom as any).booted) {
      this.Intercom('boot', this.intercomConfig);
    }
  }

  public hide(): void {
    if (verbose) {
      console.log("Sending Intercom('hide')");
    }

    this.Intercom('hide');
  }

  public show(): void {
    if (verbose) {
      console.log("Sending Intercom('show')");
    }

    this.Intercom('show');
  }

  public showMessages(): void {
    if (verbose) {
      console.log("Sending Intercom('showMessages')");
    }

    this.Intercom('showMessages');
  }

  public showNewMessage(): void {
    if (verbose) {
      console.log("Sending Intercom('showNewMessage')");
    }

    this.Intercom('showNewMessage');
  }

  // tslint:disable-next-line ban-types
  public onHide(callback: Function): void {
    if (verbose) {
      console.log("Sending Intercom('onHide') with:", callback);
    }

    this.Intercom('onHide', callback);
  }

  // tslint:disable-next-line ban-types
  public onShow(callback: Function): void {
    if (verbose) {
      console.log("Sending Intercom('onShow') with:", callback);
    }

    this.Intercom('onShow', callback);
  }

  // tslint:disable-next-line ban-types
  public onUnreadCountChange(callback: Function): void {
    if (verbose) {
      console.log("Sending Intercom('onUnreadCountChange') with:", callback);
    }

    this.Intercom('onUnreadCountChange', callback);
  }

  // public trackEvent(event: string, metadata: object): void {
  //   this.Intercom('trackEvent', event);
  // }

  public hideLauncher(): void {
    if (verbose) {
      console.log('Updating hideDefaultLauncher to true');
    }

    this.hideDefaultLauncher = true;
    // this.updateIntercom(true);
    this.updateIntercom();
  }

  public showLauncher(): void {
    if (verbose) {
      console.log('Updating hideDefaultLauncher to false');
    }

    this.hideDefaultLauncher = false;
    // this.updateIntercom(true);
    this.updateIntercom();
  }

  private updateIntercom(immediate?: boolean): void {
    clearTimeout(this.updateTimeout);

    if (immediate) {
      if (verbose) {
        console.log('Updating Intercom with data:');
        console.log(this.intercomConfig);
      }

      this.Intercom('update', this.intercomConfig);
      return;
    }

    this.updateTimeout = setTimeout(() => {
      if (verbose) {
        console.log('Updating Intercom with data:');
        console.log(this.intercomConfig);
      }

      this.Intercom('update', this.intercomConfig);
    }, this.updateThrottle);
  }

  public set companyId(newCompanyId: string) {
    if (newCompanyId) {
      if (this._companyId !== newCompanyId) {
        if (verbose) {
          console.log(`Updating companyId to ${newCompanyId}`);
        }

        this._companyId = newCompanyId;
        this.updateIntercom();
      }
    }
  }

  public set companyCreatedAt(newCompanyCreatedAt: Date) {
    if (newCompanyCreatedAt) {
      if (this._companyCreatedAt !== newCompanyCreatedAt) {
        if (verbose) {
          console.log(
            `Updating companyCreatedAt to ${new Date(newCompanyCreatedAt)}`,
          );
        }

        this._companyCreatedAt = new Date(newCompanyCreatedAt);
        this.updateIntercom();
      }
    }
  }

  public set companyName(newCompanyName: string) {
    if (newCompanyName) {
      if (this._companyName !== newCompanyName) {
        if (verbose) {
          console.log(`Updating companyName to ${newCompanyName}`);
        }

        this._companyName = newCompanyName;
        this.updateIntercom();
      }
    }
  }

  public set createdAt(newCreatedAt: Date) {
    if (newCreatedAt) {
      if (this._createdAt !== newCreatedAt) {
        if (verbose) {
          console.log(`Updating createdAt to ${new Date(newCreatedAt)}`);
        }

        this._createdAt = new Date(newCreatedAt);
        this.updateIntercom();
      }
    }
  }

  public set email(newEmail: string) {
    if (newEmail) {
      if (this._email !== newEmail) {
        if (verbose) {
          console.log(`Updating email to ${newEmail}`);
        }

        this._email = newEmail;
        this.updateIntercom();
      }
    }
  }

  public set name(newName: string) {
    if (newName) {
      if (this._name !== newName) {
        if (verbose) {
          console.log(`Updating name to ${newName}`);
        }

        this._name = newName;
        this.updateIntercom();
      }
    }
  }

  public set userHash(newUserHash: string) {
    if (newUserHash) {
      if (this._userHash !== newUserHash) {
        if (verbose) {
          console.log(`Updating userHash to ${newUserHash}`);
        }

        this._userHash = newUserHash;
        this.updateIntercom();
      }
    }
  }

  public set userId(newUserId: string) {
    if (newUserId) {
      if (this._userId !== newUserId) {
        if (verbose) {
          console.log(`Updating userId to ${newUserId}`);
        }

        this._userId = newUserId;
        this.updateIntercom();
      }
    }
  }

  private get intercomUser(): KeeroIntercomUser {
    const intercomUser: KeeroIntercomUser = {
      keero_last_seen: new Date(),
    };

    if (this._companyId || this._companyCreatedAt || this._companyName) {
      intercomUser.company = {};
      if (this._companyId) {
        intercomUser.company.company_id = this._companyId;
      }
      if (this._companyCreatedAt) {
        intercomUser.company.created_at = this._companyCreatedAt;
      }
      if (this._companyName) {
        intercomUser.company.name = this._companyName;
      }
    }
    if (this._createdAt) {
      intercomUser.created_at = this._createdAt;
    }
    if (this._email) {
      intercomUser.email = this._email;
    }
    if (this._name) {
      intercomUser.name = this._name;
    }
    if (this._userHash) {
      intercomUser.user_hash = this._userHash;
    }
    if (this._userId) {
      intercomUser.user_id = this._userId;
    }

    return intercomUser;
  }

  private get intercomConfig(): IntercomConfig {
    const config: IntercomConfig = {
      app_id: this._appId,
      ...this.intercomUser,
    };

    if (this.hideDefaultLauncher) {
      config.hide_default_launcher = this.hideDefaultLauncher;
    }

    if (this.customLauncherSelector) {
      config.custom_launcher_selector = this.customLauncherSelector;
    }

    return config;
  }
}
