import { ActionInterface, SuccessAction } from '../../helpers/actionBuilder';

import { Settings } from './';

/**
 * Show sidebar
 */
export const showSidebar = (): ActionInterface => {
  return new SuccessAction(Settings.sidebar.show).json;
};

/**
 * Hide sidebar
 */
export const hideSidebar = (): ActionInterface => {
  return new SuccessAction(Settings.sidebar.hide).json;
};

/**
 * Toggle sidebar
 */
export const toggleSidebar = (): ActionInterface => {
  return new SuccessAction(Settings.sidebar.toggle).json;
};

/**
 * Set theme
 */
export const setTheme = (theme: string): ActionInterface => {
  document.documentElement.classList.add('color-theme-in-transition');
  document.documentElement.setAttribute('data-theme', theme);
  window.setTimeout(() => {
    document.documentElement.classList.remove('color-theme-in-transition');
  }, 1000);
  localStorage.setItem('nv-snowdon-theme', theme);
  return new SuccessAction(Settings.theme.set, { theme }).json;
};

/**
 * Enable notifications
 */
export const enableNotifications = (): ActionInterface => {
  return new SuccessAction(Settings.notifications.enable).json;
};

/**
 * Disable notifications
 */
export const disableNotifications = (): ActionInterface => {
  return new SuccessAction(Settings.notifications.disable).json;
};

/**
 * Toggle notifications
 */
export const toggleNotifications = (): ActionInterface => {
  return new SuccessAction(Settings.notifications.toggle).json;
};
