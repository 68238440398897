import {
  ActionInterface,
  ErrorAction,
  SuccessAction,
} from '../../helpers/actionBuilder';
import { Lobby, Toasts } from './';

import { lcFetchAllPages } from '../../helpers/lcFetch';

import Message from '../../interfaces/Chat/Message';
import MessagesResponse from '../../interfaces/Chat/MessagesResponse';
import State from '../../interfaces/State';

/**
 * Empty downloaded lobby list
 */
export const clearLobby = (): ActionInterface => {
  return new SuccessAction(Lobby.clear).json;
};

/**
 * Get lobby messages
 */
// tslint:disable-next-line no-any
export const fetchLobbyMessages = (team: string = ''): any => {
  return (
    dispatch: (action: ActionInterface) => void,
    getState: () => State,
  ) => {
    dispatch(new SuccessAction(Lobby.fetching).json);

    // TODO customise pageSize (limit)
    lcFetchAllPages(
      `teams/${team}/conversations/lobby/messages`,
      getState().user.accessToken,
      'GET',
      {},
      {},
      5,
      true,
    )
      .then((messages: MessagesResponse) => {
        dispatch(new SuccessAction(Lobby.messages.received, messages).json);

        dispatch(new SuccessAction(Lobby.fetchingDone).json);
      })
      .catch((error: Error) => {
        dispatch(new ErrorAction(Lobby.fetchingDone, error).json);

        dispatch(
          new SuccessAction(Toasts.push, {
            delay: 10000,
            message: `There was a problem fetching messages: ${error.message}`,
            type: 'negative',
          }).json,
        );
      });
  };
};

/**
 * Receive a message
 * TODO this needs generalising with conversations action messageReceived
 */
export const lobbyMessageReceived = (
  message: Message,
  showNotification: boolean = false,
): ActionInterface => {
  return new SuccessAction(Lobby.messages.received, {
    items: [message],
  }).json;
};

/**
 * Specific message update
 */
export const lobbyMessageUpdated = (message: Message): ActionInterface => {
  return new SuccessAction(Lobby.messages.updated, message).json;
};

/**
 * Remove specific message
 */
export const lobbyMessageDeleted = (message: Message): ActionInterface => {
  return new SuccessAction(Lobby.messages.deleted, message).json;
};

/**
 * A map to store user typing states keyed on user id.
 * Value is a timeout which dispatches a
 * userStoppedTyping event on completion
 */
const typingMap = new Map();

/**
 * Mark lobby as typing
 */
// tslint:disable-next-line no-any
export const lobbyUserTyping = (user: string, finished: boolean): any => {
  return (
    dispatch: (action: ActionInterface) => void,
    getState: () => State,
  ) => {
    if (finished) {
      dispatch(new SuccessAction(Lobby.userStoppedTyping, { user }).json);
      return;
    }

    dispatch(new SuccessAction(Lobby.userStartedTyping, { user }).json);

    const identifier = `${user}`;

    // Find and clear previous stopTyping timeout
    const prevTimeout = typingMap.get(identifier);
    if (prevTimeout) {
      clearTimeout(prevTimeout);
    }

    // Stop typing dispatcher
    const stopTyping = () => {
      dispatch(new SuccessAction(Lobby.userStoppedTyping, { user }).json);
    };

    // Create and set a timeout in the map to clear typing after 3 seconds
    const timeout = setTimeout(stopTyping, 3000);
    typingMap.set(identifier, timeout);
  };
};
