import * as conversations from './conversations';
import * as linker from './linker';
import * as lobby from './lobby';
import * as settings from './settings';
import * as tags from './tags';
import * as tasks from './tasks';
import * as team from './team';
import * as teams from './teams';
import * as toasts from './toasts';
import * as user from './user';
import * as userMessages from './userMessages';
import * as users from './users';

export {
  conversations,
  linker,
  lobby,
  settings,
  tags,
  tasks,
  team,
  teams,
  toasts,
  user,
  userMessages,
  users,
};

export const Conversations = {
  clear: 'CONVERSATIONS_CLEAR',
  conversation: {
    clear: 'CONVERSATIONS_CONVERSATION_CLEAR',
    delete: 'CONVERSATIONS_CONVERSATION_DELETE',
    fetching: 'CONVERSATIONS_CONVERSATION_FETCHING',
    fetchingDone: 'CONVERSATIONS_CONVERSATION_FETCHING_DONE',
    messages: {
      deleted: 'CONVERSATIONS_CONVERSATION_MESSAGE_DELETED',
      fetching: 'CONVERSATIONS_CONVERSATION_MESSAGES_FETCHING',
      fetchingDone: 'CONVERSATIONS_CONVERSATION_MESSAGES_FETCHING_DONE',
      received: 'CONVERSATIONS_CONVERSATION_RECEIVE_MESSAGES',
      updated: 'CONVERSATIONS_CONVERSATION_MESSAGE_UPDATED',
    },
    recalculateUnread: 'CONVERSATIONS_UNREAD_RECALCULATE',
    received: 'CONVERSATIONS_CONVERSATION_RECEIVE',
    userJoined: 'CONVERSATIONS_CONVERSATION_USER_JOINED',
    userLeft: 'CONVERSATIONS_CONVERSATION_USER_LEFT',
    userStartedTyping: 'CONVERSATIONS_CONVERSATION_USER_STARTED_TYPING',
    userStoppedTyping: 'CONVERSATIONS_CONVERSATION_USER_STOPPED_TYPING',
  },
  fetching: 'CONVERSATIONS_FETCHING',
  fetchingDone: 'CONVERSATIONS_FETCHING_DONE',
  inbox: {
    fetching: 'CONVERSATIONS_FETCHING_INBOX',
    fetchingDone: 'CONVERSATIONS_FETCHING_INBOX_DONE',
    received: 'CONVERSATIONS_RECEIVE_INBOX',
  },
  receive: 'CONVERSATIONS_RECEIVE',
};

export const Tasks = {
  clear: 'TASKS_CLEAR',
  fetching: 'TASKS_FETCHING',
  fetchingDone: 'TASKS_FETCHING_DONE',
  receive: 'TASKS_RECEIVE',
  task: {
    clear: 'TASKS_TASK_CLEAR',
  },
};

export const Tags = {
  clear: 'TAGS_CLEAR',
  fetching: 'TAGS_FETCHING',
  fetchingDone: 'TAGS_FETCHING_DONE',
  receive: 'TAGS_RECEIVE',
  tag: {
    clear: 'TAGS_TASK_CLEAR',
  },
};

export const Linker = {
  linkToMessage: 'LINKER_LINK_TO_MESSAGE',
};

export const Lobby = {
  clear: 'LOBBY_CLEAR',
  clearUnread: 'LOBBY_UNREAD_CLEAR',
  fetching: 'LOBBY_FETCHING',
  fetchingDone: 'LOBBY_FETCHING_DONE',
  messages: {
    deleted: 'LOBBY_MESSAGE_DELETED',
    received: 'LOBBY_RECEIVE_MESSAGES',
    updated: 'LOBBY_MESSAGE_UPDATED',
  },
  userStartedTyping: 'LOBBY_USER_STARTED_TYPING',
  userStoppedTyping: 'LOBBY_USER_STOPPED_TYPING',
};

export const Settings = {
  intercom: {
    hide: 'SETTINGS_INTERCOM_HIDE',
    setUnreadCount: 'SETTINGS_INTERCOM_SET_UNREAD_COUNT',
    show: 'SETTINGS_INTERCOM_SHOW',
    toggle: 'SETTINGS_INTERCOM_TOGGLE',
  },
  notifications: {
    disable: 'SETTINGS_NOTIFICATIONS_DISABLE',
    enable: 'SETTINGS_NOTIFICATIONS_ENABLE',
    toggle: 'SETTINGS_NOTIFICATIONS_TOGGLE',
  },
  sidebar: {
    hide: 'SETTINGS_SIDEBAR_HIDE',
    show: 'SETTINGS_SIDEBAR_SHOW',
    toggle: 'SETTINGS_SIDEBAR_TOGGLE',
  },
  theme: {
    set: 'SETTINGS_THEME_SET',
  },
};

export const Team = {
  clear: 'TEAM_CLEAR',
  fetching: 'TEAM_FETCHING',
  fetchingDone: 'TEAM_FETCHING_DONE',
  receive: 'TEAM_RECEIVE',
  receiveConversations: 'TEAM_RECEIVE_CONVERSATIONS',
};

export const Teams = {
  clear: 'TEAMS_CLEAR',
  fetching: 'TEAMS_FETCHING',
  fetchingDone: 'TEAMS_FETCHING_DONE',
  receive: 'TEAMS_RECEIVE',
};

export const Toasts = {
  clear: 'TOASTS_CLEAR',
  preremove: 'TOASTS_PREREMOVE',
  push: 'TOASTS_PUSH',
  remove: 'TOASTS_REMOVE',
  shift: 'TOASTS_SHIFT',
};

export const User = {
  authenticating: 'USER_AUTHENTICATING',
  authenticatingDone: 'USER_AUTHENTICATING_DONE',
  fetching: 'USER_FETCHING',
  fetchingDone: 'USER_FETCHING_DONE',
  login: 'USER_LOGIN',
  logout: 'USER_LOGOUT',
  receive: 'USER_RECEIVE',
};

export const UserMessages = {
  clear: 'USER_MESSAGES_CLEAR',
  conversation: {
    recalculateUnread: 'USER_MESSAGES_CONVERSATION_RECALCULATE_UNREAD',
    userStartedTyping: 'USER_MESSAGES_CONVERSATION_USER_STARTED_TYPING',
    userStoppedTyping: 'USER_MESSAGES_CONVERSATION_USER_STOPPED_TYPING',
  },
  deleted: 'USER_MESSAGES_DELETED',
  fetching: 'USER_MESSAGES_FETCHING',
  fetchingDone: 'USER_MESSAGES_FETCHING_DONE',
  received: 'USER_MESSAGES_RECEIVED',
  setUnreads: 'USER_MESSAGES_SET_UNREAD',
  updated: 'USER_MESSAGES_UPDATED',
};

export const Users = {
  clear: 'USERS_CLEAR',
  fetching: 'USERS_FETCHING',
  fetchingDone: 'USERS_FETCHING_DONE',
  receive: 'USERS_RECEIVE',
  updateUserPresence: 'USERS_UPDATE_PRESENCE',
};
