import deepFreeze from 'deep-freeze';

import { Settings } from '../actions';

import SettingsInterface from '../../interfaces/Settings';

import { ActionInterface } from '../../helpers/actionBuilder';
import { IS_PROD, THEMES } from '../../helpers/constants';

export const initialState: SettingsInterface = {
  notificationsEnabled: true,
  showSidebar: false,
  socketConnected: false,
  theme: THEMES.LIGHT,
};

/**
 * Toggle sidebar
 */
const hideSidebar = (
  settings: SettingsInterface = initialState,
): SettingsInterface => {
  return { ...settings, showSidebar: false };
};

/**
 * Toggle sidebar
 */
const showSidebar = (
  settings: SettingsInterface = initialState,
): SettingsInterface => {
  return { ...settings, showSidebar: true };
};

/**
 * Toggle sidebar
 */
const toggleSidebar = (
  settings: SettingsInterface = initialState,
): SettingsInterface => {
  return { ...settings, showSidebar: !settings.showSidebar };
};

/**
 * Set theme
 */
const setTheme = (
  settings: SettingsInterface = initialState,
  theme: string = THEMES.LIGHT || THEMES.DARK || THEMES.BLACK,
): SettingsInterface => {
  return { ...settings, theme };
};

/**
 * Enable notifications
 */
const enableNotifications = (
  settings: SettingsInterface = initialState,
): SettingsInterface => {
  return { ...settings, notificationsEnabled: true };
};

/**
 * Disable notifications
 */
const disableNotifications = (
  settings: SettingsInterface = initialState,
): SettingsInterface => {
  return { ...settings, notificationsEnabled: false };
};

/**
 * Toggle notifications
 */
const toggleNotifications = (
  settings: SettingsInterface = initialState,
): SettingsInterface => {
  return {
    ...settings,
    notificationsEnabled: !settings.notificationsEnabled,
  };
};

export default (
  state: SettingsInterface = initialState,
  action: ActionInterface,
): SettingsInterface => {
  if (!IS_PROD) {
    // Ensure state never gets mutated
    deepFreeze(state);
  }

  switch (action.type) {
    case Settings.sidebar.hide:
      return hideSidebar(state);

    case Settings.sidebar.show:
      return showSidebar(state);

    case Settings.sidebar.toggle:
      return toggleSidebar(state);

    case Settings.theme.set:
      return setTheme(state, action.payload.theme);

    case Settings.notifications.enable:
      return enableNotifications(state);

    case Settings.notifications.disable:
      return disableNotifications(state);

    case Settings.notifications.toggle:
      return toggleNotifications(state);

    default:
      return state;
  }
};
