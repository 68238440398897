import deepFreeze from 'deep-freeze';
import { merge } from 'lodash';

import { User } from '../actions';

import UserInterface from '../../interfaces/User';

import { ActionInterface } from '../../helpers/actionBuilder';
import { IS_PROD } from '../../helpers/constants';

export const initialState: UserInterface = {
  accessLevel: 'EXTENSION',
  accessToken: '',
  authenticating: false,
  created: new Date(),
  displayName: '',
  email: '',
  expires: new Date(),
  firstName: '',
  id: '',
  lastName: '',
  locale: 'en',
  message: '',
  rememberMe: false,
  type: 'user',
  uri: '',
  userId: '',
  username: '',
};

/**
 * Auth succeeded, store auth details
 */
const authenticationSucceeded = (
  user: UserInterface = initialState,
  accessToken: string | undefined = initialState.accessToken,
  expires: Date | undefined = initialState.expires,
  rememberMe: boolean | undefined = initialState.rememberMe,
): UserInterface => {
  const nextUser: UserInterface = merge({}, user);

  nextUser.accessToken = accessToken;
  nextUser.authenticating = false;
  nextUser.expires = expires;
  nextUser.rememberMe = rememberMe;
  nextUser.message = initialState.message;

  return nextUser;
};

/**
 * Fetch all fields for currently authed user
 */
const fetching = (user: UserInterface = initialState): UserInterface => {
  const nextUser: UserInterface = merge({}, user, { fetching: true });

  return nextUser;
};

/**
 * Fetch all fields for currently authed user
 */
const receiveUser = (
  user: UserInterface = initialState,
  fetchedUser: UserInterface = initialState,
): UserInterface => {
  const nextUser: UserInterface = merge({}, user);

  nextUser.accessLevel = fetchedUser.accessLevel;
  nextUser.created = fetchedUser.created;
  nextUser.email = fetchedUser.email;
  nextUser.firstName = fetchedUser.firstName;
  nextUser.id = fetchedUser.id;
  nextUser.lastName = fetchedUser.lastName;
  nextUser.locale = fetchedUser.locale;
  nextUser.message = '';
  nextUser.uri = fetchedUser.uri;
  nextUser.username = fetchedUser.username;
  nextUser.userId = fetchedUser.userId;

  if (!fetchedUser.displayName) {
    nextUser.displayName = fetchedUser.firstName + ' ' + fetchedUser.lastName;
  }

  return nextUser;
};

/**
 * Complete fetching, success
 */
const fetchingSucceeded = (
  user: UserInterface = initialState,
): UserInterface => {
  return merge({}, user, { fetching: false });
};

/**
 * Complete fetching, failure
 */
const fetchingFailed = (
  user: UserInterface = initialState,
  message: string = initialState.message,
): UserInterface => {
  return merge({}, user, { fetching: false, message });
};

/**
 * Log out, clear out the user
 */
const logout = (): UserInterface => {
  return initialState;
};

export default (
  state: UserInterface = initialState,
  action: ActionInterface,
): UserInterface => {
  if (!IS_PROD) {
    // Ensure state never gets mutated
    deepFreeze(state);
  }

  switch (action.type) {
    case User.authenticatingDone:
      return authenticationSucceeded(
        state,
        action.payload.accessToken,
        action.payload.expires,
        action.payload.rememberMe,
      );

    case User.fetching:
      return fetching(state);

    case User.receive:
      return receiveUser(state, action.payload);

    case User.fetchingDone:
      if (action.error) {
        return fetchingFailed(state, action.payload.message);
      } else {
        return fetchingSucceeded(state);
      }

    case User.logout:
      return logout();

    default:
      return state;
  }
};
