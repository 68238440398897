import deepFreeze from 'deep-freeze';
import { merge } from 'lodash';

import { Users } from '../actions';

import User from '../../interfaces/Chat/User';
import UsersInterface from '../../interfaces/Chat/Users';
import UsersResponse from '../../interfaces/Chat/UsersResponse';

import { ActionInterface } from '../../helpers/actionBuilder';
import { IS_PROD } from '../../helpers/constants';
import deduplicate from '../../helpers/deduplicate';

export const initialState: UsersInterface = {
  fetching: false,
  items: [],
  message: '',
};

export const initialUser: User = {
  displayName: '',
  email: '',
  enabled: true,
  firstName: '',
  id: '',
  lastName: '',
  presence: 'OFFLINE',
  role: 'MEMBER',
  status: {
    emoji: '',
    text: '',
  },
  type: '',
  username: '',
};

/**
 * Empty downloaded users list
 */
const clear = (): UsersInterface => initialState;

/**
 * Initiate fetching
 */
const fetching = (users: UsersInterface = initialState): UsersInterface => {
  return merge({}, users, { fetching: true });
};

/**
 * Complete fetching, success
 */
const receiveUsers = (
  users: UsersInterface = initialState,
  fetchedUsers: UsersResponse,
): UsersInterface => {
  if (!fetchedUsers.items) {
    return users;
  }

  let items = fetchedUsers.items.concat(users.items);

  items = items
    .filter((user: User) => user.firstName && user.lastName)
    .map((user: User) => ({
      ...user,
    }));

  // Deduplicate users
  items = deduplicate(items);

  // Filter disabled users
  items = items.filter((user) => user.enabled);

  items = items.sort((a, b) => {
    const aName = `${a.displayName}`.toLowerCase();
    const bName = `${b.displayName}`.toLowerCase();
    if (aName < bName) {
      return -1;
    }
    if (aName > bName) {
      return 1;
    }
    return 0;
  });

  const nextUsers: UsersInterface = merge({}, users, { items });

  return nextUsers;
};

/**
 * Complete fetching, success
 */
const fetchingSucceeded = (
  users: UsersInterface = initialState,
): UsersInterface => {
  return merge({}, users, { fetching: false });
};

/**
 * Complete fetching, failure
 */
const fetchingFailed = (
  users: UsersInterface = initialState,
  message: string = initialState.message,
): UsersInterface => {
  return merge({}, users, { fetching: false, message });
};

/**
 * Update a user's presence
 */
const updateUserPresence = (
  users: UsersInterface = initialState,
  presence: {
    teamId: string;
    userId: string;
    presence: 'ONLINE' | 'OFFLINE';
  } = {
    presence: 'OFFLINE',
    teamId: '',
    userId: '',
  },
): UsersInterface => {
  const usersList = users.items;

  const user = usersList.find((user) => user.id === presence.userId);

  if (!user) {
    return users;
  }

  const nextUser = {
    ...user,
    presence: presence.presence,
  };

  return receiveUsers(users, {
    items: [nextUser],
  });
};

export default (
  state = initialState,
  action: ActionInterface,
): UsersInterface => {
  if (!IS_PROD) {
    // Ensure state never gets mutated
    deepFreeze(state);
  }

  switch (action.type) {
    case Users.clear:
      return clear();

    case Users.fetching:
      return fetching(state);

    case Users.receive:
      return receiveUsers(state, action.payload);

    case Users.fetchingDone:
      if (action.error) {
        return fetchingFailed(state, action.payload.message);
      } else {
        return fetchingSucceeded(state);
      }

    case Users.updateUserPresence:
      return updateUserPresence(state, action.payload);

    default:
      return state;
  }
};
