import { connectRouter } from 'connected-react-router';
import { History } from 'history';
import { combineReducers } from 'redux';

import conversations from './conversations';
import lobby from './lobby';
import settings from './settings';
import tags from './tags';
import tasks from './tasks';
import team from './team';
import teams from './teams';
import toasts from './toasts';
import user from './user';
import userMessages from './userMessages';
import users from './users';

const rootReducer = (history: History) =>
  combineReducers({
    conversations,
    lobby,
    router: connectRouter(history),
    settings,
    tags,
    tasks,
    team,
    teams,
    toasts,
    user,
    userMessages,
    users,
  });

export default rootReducer;
