import deepFreeze from 'deep-freeze';
import { merge } from 'lodash';

import { UserMessages } from '../actions';

import UserMessageInterface from '../../interfaces/Chat/UserMessage';
import UserMessageConversationInterface from '../../interfaces/Chat/UserMessageConversation';
import UserMessagesInterface from '../../interfaces/Chat/UserMessages';
import UserMessagesResponse from '../../interfaces/Chat/UserMessagesResponse';

import { ActionInterface } from '../../helpers/actionBuilder';
import { IS_PROD } from '../../helpers/constants';
import { deduplicate } from '../../helpers/deduplicate';

export const initialState: UserMessagesInterface = {
  fetching: false,
  items: [],
  message: '',
};

export const initialUserMessageConversation: UserMessageConversationInterface = {
  fetching: false,
  id: '',
  messages: [],
  name: 'Direct Message',
  participants: [],
  typingUsers: [],
  unreadMessagesCount: 0,
};

export const initialUserMessage: UserMessageInterface = {
  attachments: [],
  dmid: '',
  edited: new Date(),
  files: [],
  id: '',
  owner: '',
  participants: [],
  posted: new Date(),
  reactions: [],
  read: true,
  team: '',
  text: '',
  type: '',
};

const clearMessages = (): UserMessagesInterface => initialState;

const fetchingMessages = (
  userMessages: UserMessagesInterface = initialState,
): UserMessagesInterface => {
  return merge({}, userMessages, { fetching: true });
};

const receiveMessages = (
  userMessages: UserMessagesInterface = initialState,
  fetchedMessages: UserMessagesResponse,
): UserMessagesInterface => {
  if (!fetchedMessages.items) {
    return userMessages;
  }

  let nextUMConvs = userMessages.items;

  const messages = fetchedMessages.items;

  // Oh boy here we go... 🐸 🚲

  // For each message try to find the related conversation
  messages.forEach((message) => {
    // See if this message references a conversation we already have
    const conversation = nextUMConvs.find((x) => x.id === message.dmid);

    if (conversation) {
      // We have the conversation, so check if this id exists in it's messages
      const convHasMessage = conversation.messages.some(
        (x) => x.id === message.id,
      );

      // Does the conversation already have this message?
      const nextMessages = convHasMessage
        ? // It does, so replace the existing message with newly received one
          conversation.messages.map((x) =>
            x.id === message.id
              ? {
                  ...message,
                  read: x.read || message.read, // Don't let a message be marked as unread
                }
              : x,
          )
        : [
            // It doesn't, so append new message to existing messages
            ...conversation.messages,
            message,
          ];

      // Sort the messages by posted date
      const sortedMessages = nextMessages.sort(
        (a: UserMessageInterface, b: UserMessageInterface): number =>
          new Date(a.posted).getTime() - new Date(b.posted).getTime(),
      );

      // Our new conversation object
      const nextUMConv: UserMessageConversationInterface = {
        ...conversation,
        messages: [...sortedMessages],
        unreadMessagesCount: sortedMessages.filter((x) => !x.read).length,
      };

      // Update our conversation in nextUMConvs
      nextUMConvs = nextUMConvs.map(
        (x) =>
          x.id === nextUMConv.id
            ? nextUMConv // Replace witn newly updated conversation
            : x, // Return existing conversation
      );
    } else {
      // Create a new conversation, set it's id and messages
      const nextUMConv = {
        ...initialUserMessageConversation,
        id: message.dmid,
        messages: [message],
        participants: message.participants,
        unreadMessagesCount: message.read ? 0 : 1,
      };

      // Add the new conversation to the existing conversations
      nextUMConvs = [...nextUMConvs, nextUMConv];
    }
  });

  // Aaaand we're done! 🅾️ 💩 ❓ 🆙

  const dedupedUMConvs = deduplicate(nextUMConvs);

  return {
    ...userMessages,
    items: dedupedUMConvs,
  };
};

const fetchingMessagesSucceeded = (
  userMessages: UserMessagesInterface = initialState,
): UserMessagesInterface => {
  return merge({}, userMessages, { fetching: false });
};

const fetchingMessagesFailed = (
  userMessages: UserMessagesInterface = initialState,
  message: string = initialState.message,
): UserMessagesInterface => {
  return merge({}, userMessages, { fetching: false, message });
};

const recalculateUnreadMessages = (
  userMessages: UserMessagesInterface = initialState,
  dmid: string,
): UserMessagesInterface => {
  const conversation = userMessages.items.find(
    (conversation) => conversation.id === dmid,
  );

  const nextConversation = {
    ...conversation,
    unreadMessagesCount: conversation.messages.filter(
      (message) => !message.read,
    ).length,
  };

  // Update conversation
  const nextConversations = userMessages.items.map(
    (x) =>
      x.id === conversation.id
        ? nextConversation // Update conversation
        : x, // Return existing conversation
  );

  return {
    ...userMessages,
    items: nextConversations,
  };
};

const messageDeleted = (
  userMessages: UserMessagesInterface = initialState,
  messageToDelete: UserMessageInterface,
): UserMessagesInterface => {
  return {
    ...userMessages,
    items: userMessages.items.map((conversation) =>
      conversation.id === messageToDelete.dmid
        ? {
            ...conversation,
            messages: conversation.messages.filter(
              (message) => message.id !== messageToDelete.id,
            ),
          }
        : conversation,
    ),
  };
};

const messageUpdated = (
  userMessages: UserMessagesInterface = initialState,
  message: UserMessageInterface,
): UserMessagesInterface => {
  const conversation = userMessages.items.find((x) => x.id === message.dmid);

  // Update message
  const messages = conversation.messages.map(
    (x) =>
      x.id === message.id
        ? message // Update message
        : x, // Return existing message
  );

  const nextConversation = {
    ...conversation,
    messages,
    unreadMessagesCount: messages.filter((x) => !x.read).length,
  };

  // Update conversation
  const nextConversations = userMessages.items.map(
    (x) =>
      x.id === conversation.id
        ? nextConversation // Update conversation
        : x, // Return existing conversation
  );

  return {
    ...userMessages,
    items: nextConversations,
  };
};

const setUnreads = (
  userMessages: UserMessagesInterface = initialState,
  {
    memberId,
    userId,
    count,
  }: {
    memberId: string;
    userId: string;
    count: number;
  },
): UserMessagesInterface => {
  const conversation = userMessages.items.find((x) =>
    x.participants.includes(memberId),
  );

  let nextItems = [];

  if (conversation) {
    const nextConversation = {
      ...conversation,
      unreadMessagesCount: count,
    };

    nextItems = userMessages.items.map(
      (x) =>
        x.id === conversation.id
          ? nextConversation // Update conversation
          : x, // Return existing conversation
    );
  } else {
    const participants = [userId, memberId];
    let dmid = participants.sort((a, b) => Number(a) - Number(b)).join(':');
    dmid = btoa(dmid);
    const nextConversation = {
      ...initialUserMessageConversation,
      id: dmid,
      participants,
      unreadMessagesCount: count,
    };

    nextItems = [nextConversation];
  }

  return {
    ...userMessages,
    items: nextItems,
  };
};

const userStartedTyping = (
  userMessages: UserMessagesInterface = initialState,
  data: {
    dmid: string;
    user: string;
  },
): UserMessagesInterface => {
  const conversation = userMessages.items.find((x) => x.id === data.dmid);

  // If we can't find the conversation, just return
  if (!conversation) {
    return {
      ...userMessages,
    };
  }

  let { typingUsers } = conversation;

  // Add the typing user
  if (!typingUsers.includes(data.user)) {
    typingUsers = [...typingUsers, data.user];
  }

  // Build the next conversation
  const nextConversation = {
    ...conversation,
    typingUsers,
  };

  // Replace the existing conversation with the updated conversation
  const nextItems = userMessages.items.map((x) =>
    x.id === data.dmid ? nextConversation : x,
  );

  return {
    ...userMessages,
    items: nextItems,
  };
};

const userStoppedTyping = (
  userMessages: UserMessagesInterface = initialState,
  data: {
    dmid: string;
    user: string;
  },
): UserMessagesInterface => {
  const conversation = userMessages.items.find((x) => x.id === data.dmid);

  // If we can't find the conversation, just return
  if (!conversation) {
    return {
      ...userMessages,
    };
  }

  const typingUsers = conversation.typingUsers.filter((x) => x !== data.user);

  // Build the next conversation
  const nextConversation = {
    ...conversation,
    typingUsers,
  };

  // Replace the existing conversation with the updated conversation
  const nextItems = userMessages.items.map((x) =>
    x.id === data.dmid ? nextConversation : x,
  );

  return {
    ...userMessages,
    items: nextItems,
  };
};

export default (
  state = initialState,
  action: ActionInterface,
): UserMessagesInterface => {
  if (!IS_PROD) {
    // Ensure state never gets mutated
    deepFreeze(state);
  }

  switch (action.type) {
    case UserMessages.clear:
      return clearMessages();

    case UserMessages.fetching:
      return fetchingMessages(state);

    case UserMessages.received:
      return receiveMessages(state, action.payload);

    case UserMessages.setUnreads:
      return setUnreads(state, action.payload);

    case UserMessages.fetchingDone:
      if (action.error) {
        return fetchingMessagesFailed(state, action.payload.message);
      } else {
        return fetchingMessagesSucceeded(state);
      }

    case UserMessages.conversation.recalculateUnread:
      return recalculateUnreadMessages(state, action.payload);

    case UserMessages.deleted:
      return messageDeleted(state, action.payload);

    case UserMessages.updated:
      return messageUpdated(state, action.payload);

    case UserMessages.conversation.userStartedTyping:
      return userStartedTyping(state, action.payload);

    case UserMessages.conversation.userStoppedTyping:
      return userStoppedTyping(state, action.payload);

    default:
      return state;
  }
};
