import deepFreeze from 'deep-freeze';
import { merge } from 'lodash';

import { Teams } from '../actions';

import TeamInterface from '../../interfaces/Team';
import TeamsInterface from '../../interfaces/Teams';

import { ActionInterface } from '../../helpers/actionBuilder';
import { IS_PROD } from '../../helpers/constants';
import deduplicate from '../../helpers/deduplicate';

export const initialState: TeamsInterface = {
  fetching: false,
  items: [],
  message: '',
  nextPage: '',
  page: 1,
  pageSize: 20,
  prevPage: '',
  totalItems: 0,
};

/**
 * Empty downloaded teams list
 */
const clear = (): TeamsInterface => initialState;

/**
 * Initiate fetching
 */
const fetching = (teams: TeamsInterface = initialState): TeamsInterface => {
  return merge({}, teams, { fetching: true, message: '' });
};

/**
 * Complete fetching, success
 */
const receiveTeams = (
  teams: TeamsInterface = initialState,
  fetchedTeams: TeamsInterface = initialState,
): TeamsInterface => {
  const nextTeams: TeamsInterface = merge({}, teams);

  const items: TeamInterface[] = nextTeams.items.concat(fetchedTeams.items);

  // Deduplicate the teams
  const filteredItems = deduplicate(items);

  // Sort the teams
  const sortedItems = filteredItems.sort(
    (a: TeamInterface, b: TeamInterface): number =>
      parseInt(a.id, 10) - parseInt(b.id, 10),
  );

  nextTeams.items = sortedItems;
  nextTeams.message = '';
  nextTeams.nextPage = fetchedTeams.nextPage;
  nextTeams.page = fetchedTeams.page;
  nextTeams.pageSize = fetchedTeams.pageSize;
  nextTeams.prevPage = fetchedTeams.prevPage;
  nextTeams.totalItems = fetchedTeams.totalItems;

  return nextTeams;
};

/**
 * Complete fetching, success
 */
const fetchingSucceeded = (
  teams: TeamsInterface = initialState,
): TeamsInterface => {
  return merge({}, teams, { fetching: false });
};

/**
 * Complete fetching, failure
 */
const fetchingFailed = (
  teams: TeamsInterface = initialState,
  message: string = initialState.message,
): TeamsInterface => {
  return merge({}, teams, { fetching: false, message });
};

export default (
  state: TeamsInterface = initialState,
  action: ActionInterface,
): TeamsInterface => {
  if (!IS_PROD) {
    // Ensure state never gets mutated
    deepFreeze(state);
  }

  switch (action.type) {
    case Teams.clear:
      return clear();

    case Teams.fetching:
      return fetching(state);

    case Teams.receive:
      return receiveTeams(state, action.payload);

    case Teams.fetchingDone:
      if (action.error) {
        return fetchingFailed(state, action.payload.message);
      } else {
        return fetchingSucceeded(state);
      }

    default:
      return state;
  }
};
