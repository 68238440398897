import deepFreeze from 'deep-freeze';
import { merge } from 'lodash';

import { Tasks } from '../actions';

import TaskInterface from '../../interfaces/Chat/Task';
import TasksInterface from '../../interfaces/Chat/Tasks';
import TasksResponse from '../../interfaces/Chat/TasksResponse';

import { ActionInterface } from '../../helpers/actionBuilder';
import { IS_PROD } from '../../helpers/constants';
import { deduplicate } from '../../helpers/deduplicate';

export const initialTask: TaskInterface = {
  assignedBy: '',
  assignee: '',
  completed: false,
  conversation: '',
  created: new Date(),
  description: '',
  dueBy: '',
  id: '',
  lastModified: new Date(),
  owner: '',
  read: false,
  team: '',
  title: '',
  type: 'task',
};

export const initialState: TasksInterface = {
  fetching: false,
  items: [],
  message: '',
};

/**
 * Empty downloaded tasks list
 */
const clear = (): TasksInterface => initialState;

/**
 * Initiate fetching
 */
const fetching = (tasks: TasksInterface = initialState): TasksInterface => {
  return merge({}, tasks, { fetching: true });
};

/**
 * Complete fetching, success
 */
const receiveTasks = (
  tasks: TasksInterface = initialState,
  fetchedTasks: TasksResponse,
): TasksInterface => {
  if (!fetchedTasks.items) {
    return tasks;
  }

  let items = fetchedTasks.items.concat(tasks.items);

  items = deduplicate(items);

  items = items
    .map((task) => merge({}, initialTask, task))
    .sort(
      (a: TaskInterface, b: TaskInterface): number =>
        new Date(a.created).getTime() - new Date(b.created).getTime(),
    );

  const nextTasks: TasksInterface = merge({}, tasks, { items });

  return nextTasks;
};

/**
 * Complete fetching, success
 */
const fetchingSucceeded = (
  tasks: TasksInterface = initialState,
): TasksInterface => {
  return merge({}, tasks, { fetching: false });
};

/**
 * Complete fetching, failure
 */
const fetchingFailed = (
  tasks: TasksInterface = initialState,
  message: string = initialState.message,
): TasksInterface => {
  return merge({}, tasks, { fetching: false, message });
};

/**
 * Remove a single task
 */
const clearTask = (
  tasks: TasksInterface = initialState,
  task: TaskInterface,
): TasksInterface => {
  const filteredTasks = tasks.items.filter((t) => t.id !== task.id);

  return {
    ...tasks,
    items: filteredTasks,
  };
};

export default (
  state = initialState,
  action: ActionInterface,
): TasksInterface => {
  if (!IS_PROD) {
    // Ensure state never gets mutated
    deepFreeze(state);
  }

  switch (action.type) {
    case Tasks.clear:
      return clear();

    case Tasks.fetching:
      return fetching(state);

    case Tasks.receive:
      return receiveTasks(state, action.payload);

    case Tasks.fetchingDone:
      if (action.error) {
        // console.log('action =', action);
        // return fetchingFailed(state, action.payload.message);
        return fetchingFailed(state);
      } else {
        return fetchingSucceeded(state);
      }

    case Tasks.task.clear:
      return clearTask(state, action.payload);

    default:
      return state;
  }
};
