import { IconProp } from '@fortawesome/fontawesome-svg-core';
import deepFreeze from 'deep-freeze';
import { merge } from 'lodash';

import { Tags } from '../actions';

import TagInterface from '../../interfaces/Chat/Tag';
import TagsInterface from '../../interfaces/Chat/Tags';
import TagsResponse from '../../interfaces/Chat/TagsResponse';

import { ActionInterface } from '../../helpers/actionBuilder';
import { IS_PROD } from '../../helpers/constants';
import { deduplicate } from '../../helpers/deduplicate';
import generateContrastingColour from '../../helpers/generateContrastingColour';

export const initialTag: TagInterface = {
  colour: '#',
  created: new Date(),
  icon: '' as IconProp,
  id: '',
  isArchived: false,
  name: '',
  owner: '',
  team: '',
  textColour: '',
  type: '',
};

export const initialState: TagsInterface = {
  fetching: false,
  items: [],
  message: '',
};

/**
 * Empty downloaded tags list
 */
const clear = (): TagsInterface => initialState;

/**
 * Initiate fetching
 */
const fetching = (tags: TagsInterface = initialState): TagsInterface => {
  return merge({}, tags, { fetching: true });
};

/**
 * Complete fetching, success
 */
const receiveTags = (
  tags: TagsInterface = initialState,
  fetchedTags: TagsResponse,
): TagsInterface => {
  if (!fetchedTags.items) {
    return tags;
  }

  let items = fetchedTags.items.concat(tags.items);

  items = deduplicate(items);

  items = items
    .map((tag) => merge({}, initialTag, tag))
    .map((tag) => ({
      ...tag,
      textColour: generateContrastingColour(tag.colour),
    }));

  items = items.sort((a, b) => {
    if (a.name.toLowerCase() < b.name.toLowerCase()) {
      return -1;
    }
    if (a.name.toLowerCase() > b.name.toLowerCase()) {
      return 1;
    }
    return 0;
  });

  const nextTags: TagsInterface = merge({}, tags, { items });

  return nextTags;
};

/**
 * Complete fetching, success
 */
const fetchingSucceeded = (
  tags: TagsInterface = initialState,
): TagsInterface => {
  return merge({}, tags, { fetching: false });
};

/**
 * Complete fetching, failure
 */
const fetchingFailed = (
  tags: TagsInterface = initialState,
  message: string = initialState.message,
): TagsInterface => {
  return merge({}, tags, { fetching: false, message });
};

/**
 * Remove a single tag
 */
const clearTag = (
  tags: TagsInterface = initialState,
  tag: TagInterface,
): TagsInterface => {
  const filteredTags = tags.items.filter((t) => t.id !== tag.id);

  return {
    ...tags,
    items: filteredTags,
  };
};

export default (
  state = initialState,
  action: ActionInterface,
): TagsInterface => {
  if (!IS_PROD) {
    // Ensure state never gets mutated
    deepFreeze(state);
  }

  switch (action.type) {
    case Tags.clear:
      return clear();

    case Tags.fetching:
      return fetching(state);

    case Tags.receive:
      return receiveTags(state, action.payload);

    case Tags.fetchingDone:
      if (action.error) {
        return fetchingFailed(state, action.payload.message);
      } else {
        return fetchingSucceeded(state);
      }

    case Tags.tag.clear:
      return clearTag(state, action.payload);

    default:
      return state;
  }
};
