import { isEqual, merge } from 'lodash';

import State from '../interfaces/State';

import { initialState as user } from './reducers/user';

export const initialStates = {
  user,
};

// Key to store under, helps avoid subdomain clashes
const { product } = require('../partner.json').strings;

// Storage opt-in
// Careful; adding a key here that doesn't have a reducer will cause errors.
const whitelist: string[] = ['team', 'teams', 'user'];

/**
 * Check validity of fetched store
 *
 * Return false if a deserialised state object doesn't have all the same keys
 * as its matching initialState (from the reducer), else return true
 */
// tslint:disable-next-line no-any
const validate = (state: any, stateName: string) => {
  return isEqual(merge({}, initialStates[stateName], state), state);
};

/**
 * Attempt to fetch state from localStorage, return only keys that are
 * whitelisted
 */
export const loadState = (): Partial<State> => {
  try {
    let serialisedState = localStorage.getItem(product);

    if (serialisedState === null) {
      serialisedState = sessionStorage.getItem(product);

      if (serialisedState === null) {
        return undefined;
      }
    }

    const state: State = JSON.parse(serialisedState) as State;

    const filteredState: Partial<State> = {};

    for (const reducer of whitelist) {
      if (validate(state[reducer], reducer)) {
        filteredState[reducer] = state[reducer];
      } else {
        // Stored state is malformed from initialState
        return undefined;
      }
    }

    return filteredState;
  } catch (error) {
    // Something failed
    // Play it safe and let initialState be constructed from scratch
    return undefined;
  }
};

/**
 * Put state into localStorage, store only whitelisted keys
 */
export const saveState = (state: State): void => {
  const filteredState: Partial<State> = {};

  for (const reducer of whitelist) {
    filteredState[reducer] = state[reducer];
  }

  // User doesn't want persistent storage
  if (state.user.rememberMe) {
    delete sessionStorage[product];

    try {
      const serialisedState = JSON.stringify(filteredState);

      localStorage.setItem(product, serialisedState);
    } catch (error) {
      // Ignore write errors
    }
  } else {
    delete localStorage[product];

    try {
      const serialisedState = JSON.stringify(filteredState);

      sessionStorage.setItem(product, serialisedState);
    } catch (error) {
      // Ignore write errors
    }
  }
};
