import deepFreeze from 'deep-freeze';
import { merge } from 'lodash';

import { Lobby } from '../actions';

import LobbyInterface from '../../interfaces/Chat/Lobby';
import Message from '../../interfaces/Chat/Message';
import MessagesResponse from '../../interfaces/Chat/MessagesResponse';

import { ActionInterface } from '../../helpers/actionBuilder';
import { IS_PROD } from '../../helpers/constants';
import { deduplicate } from '../../helpers/deduplicate';

export const initialState: LobbyInterface = {
  fetching: false,
  items: [],
  message: '',
  typingUsers: [],
  unreadMessagesCount: 0,
};

/**
 * Empty downloaded messages list
 */
const clear = (): LobbyInterface => initialState;

/**
 * Initiate fetching
 */
const fetching = (lobby: LobbyInterface = initialState): LobbyInterface => {
  return merge({}, lobby, { fetching: true });
};

/**
 * Complete fetching, success
 */
const fetchingSucceeded = (
  lobby: LobbyInterface = initialState,
): LobbyInterface => {
  return merge({}, lobby, { fetching: false });
};

/**
 * Complete fetching, failure
 */
const fetchingFailed = (
  lobby: LobbyInterface = initialState,
  message: string = initialState.message,
): LobbyInterface => {
  return merge({}, lobby, { fetching: false, message });
};

/**
 * Message deleted
 */
const messageDeleted = (
  lobby: LobbyInterface = initialState,
  deletedMessage: Message,
): LobbyInterface => {
  const items = lobby.items.filter((message) => {
    return message.id !== deletedMessage.id;
  });

  return {
    ...lobby,
    items,
    unreadMessagesCount: items.filter((message) => !message.read).length,
  };
};

/**
 * Store fetched messages
 */
const receiveMessages = (
  lobby: LobbyInterface = initialState,
  receivedMessages: MessagesResponse,
): LobbyInterface => {
  if (!receivedMessages.items) {
    return lobby;
  }

  const items = receivedMessages.items.concat(lobby.items);

  const filteredMessages = items.filter((message: Message) => {
    return (
      message.conversation === undefined || message.conversation === 'lobby'
    );
  });

  const remappedMessages = filteredMessages.map((message: Message) => {
    return {
      ...message,
      conversation: 'lobby',
    };
  });

  // Deduplicate the messages
  const deduplicatedMessages = deduplicate(remappedMessages);

  // Sort the messages by posted date
  const sortedMessages = deduplicatedMessages.sort(
    (a: Message, b: Message): number => {
      return new Date(a.posted).getTime() - new Date(b.posted).getTime();
    },
  );

  const unreadMessagesCount = sortedMessages.filter((message) => !message.read)
    .length;

  const nextLobby = merge(
    {},
    initialState,
    lobby,
    { items: sortedMessages },
    { unreadMessagesCount },
  );

  return nextLobby;
};

/**
 * Message updated
 */
const messageUpdated = (
  lobby: LobbyInterface = initialState,
  updatedMessage: Message,
): LobbyInterface => {
  const nextItems = lobby.items.map((message: Message) => {
    if (message.id !== updatedMessage.id) {
      return message;
    }

    return updatedMessage;
  });

  const unreadMessagesCount = nextItems.filter((message) => !message.read)
    .length;

  return {
    ...lobby,
    items: nextItems,
    unreadMessagesCount,
  };
};

/**
 * User started typing
 */
const lobbyUserStartedTyping = (
  lobby: LobbyInterface = initialState,
  data: {
    user: string;
  },
): LobbyInterface => {
  let { typingUsers } = lobby;

  // Add the typing user
  if (!typingUsers.includes(data.user)) {
    typingUsers = [...typingUsers, data.user];
  }

  return {
    ...lobby,
    typingUsers,
  };
};

/**
 * User stopped typing
 */
const lobbyUserStoppedTyping = (
  lobby: LobbyInterface = initialState,
  data: {
    user: string;
  },
): LobbyInterface => {
  const typingUsers = lobby.typingUsers.filter((x) => x !== data.user);

  return {
    ...lobby,
    typingUsers,
  };
};

export default (
  state = initialState,
  action: ActionInterface,
): LobbyInterface => {
  if (!IS_PROD) {
    // Ensure state never gets mutated
    deepFreeze(state);
  }

  switch (action.type) {
    case Lobby.clear:
      return clear();

    case Lobby.fetching:
      return fetching(state);

    case Lobby.fetchingDone:
      if (action.error) {
        return fetchingFailed(state, action.payload.message);
      } else {
        return fetchingSucceeded(state);
      }

    case Lobby.messages.deleted:
      return messageDeleted(state, action.payload);

    case Lobby.messages.received:
      return receiveMessages(state, action.payload);

    case Lobby.messages.updated:
      return messageUpdated(state, action.payload);

    case Lobby.userStartedTyping:
      return lobbyUserStartedTyping(state, action.payload);

    case Lobby.userStoppedTyping:
      return lobbyUserStoppedTyping(state, action.payload);

    default:
      return state;
  }
};
