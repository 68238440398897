import State from '../../interfaces/State';
import TeamInterface from '../../interfaces/Team';

import {
  ActionInterface,
  ErrorAction,
  SuccessAction,
} from '../../helpers/actionBuilder';
import { Team, Toasts } from './';

import { nvFetch } from '../../helpers/nvFetch';

/**
 * Empty downloaded team
 */
export const clearTeam = (): ActionInterface => {
  return new SuccessAction(Team.clear).json;
};

/**
 * Get a specific team
 */
// tslint:disable-next-line no-any
export const fetchTeam = (id: string = ''): any => {
  return (
    dispatch: (action: ActionInterface) => void,
    getState: () => State,
  ) => {
    dispatch(new SuccessAction(Team.fetching).json);

    nvFetch(`customers/${id}`, getState().user.accessToken)
      .then((team: TeamInterface) => {
        dispatch(new SuccessAction(Team.receive, team).json);

        dispatch(new SuccessAction(Team.fetchingDone).json);
      })
      .catch((error: Error) => {
        dispatch(new ErrorAction(Team.fetchingDone, error).json);

        dispatch(
          new SuccessAction(Toasts.push, {
            delay: 10000,
            message: `There was a problem fetching this team: ${error.message}`,
            type: 'negative',
          }).json,
        );
      });
  };
};

// tslint:disable-next-line no-any
export const setTeam = (team: TeamInterface): any => {
  return (
    dispatch: (action: ActionInterface) => void,
    getState: () => State,
  ) => {
    dispatch(new SuccessAction(Team.receive, team).json);
  };
};
