import UsersResponse from '../../interfaces/Chat/UsersResponse';
import State from '../../interfaces/State';

import {
  ActionInterface,
  ErrorAction,
  SuccessAction,
} from '../../helpers/actionBuilder';
import { Toasts, UserMessages, Users } from './';

import { lcFetch } from '../../helpers/lcFetch';

/**
 * Empty downloaded users list
 */
export const clearUsers = (): ActionInterface => {
  return new SuccessAction(Users.clear).json;
};

/**
 * Get a page of users
 */
// tslint:disable-next-line no-any
export const fetchUsers = (teamId: string = ''): any => {
  return (
    dispatch: (action: ActionInterface) => void,
    getState: () => State,
  ) => {
    dispatch(new SuccessAction(Users.fetching).json);

    lcFetch(`teams/${teamId}/members`, getState().user.accessToken)
      .then((users: UsersResponse) => {
        dispatch(new SuccessAction(Users.receive, users).json);

        dispatch(new SuccessAction(Users.fetchingDone).json);

        if (users.items && Array.isArray(users.items)) {
          users.items.forEach((user) => {
            if (user.unreadCount) {
              dispatch(
                new SuccessAction(UserMessages.setUnreads, {
                  count: user.unreadCount,
                  memberId: user.id,
                  userId: getState().user.userId,
                }).json,
              );
            }
          });
        }
      })
      .catch((error: Error) => {
        dispatch(new ErrorAction(Users.fetchingDone, error).json);

        dispatch(
          new SuccessAction(Toasts.push, {
            delay: 10000,
            message:
              `There was a problem fetching this team's members: ` +
              error.message,
            type: 'negative',
          }).json,
        );
      });
  };
};

/**
 * Receive a page of users
 */
export const receiveUsers = (users: UsersResponse): ActionInterface => {
  return new SuccessAction(Users.receive, users).json;
};

/**
 * Update a user's presence
 */
export const updateUserPresence = (presence: {
  teamId: string;
  userId: string;
  presence: 'ONLINE' | 'OFFLINE';
}): ActionInterface => {
  return new SuccessAction(Users.updateUserPresence, presence).json;
};
