import { merge } from 'lodash';

/**
 * Template for a Flux Standard Action
 * More info: https://github.com/acdlite/flux-standard-action
 *            (https://archive.fo/xQ5Mi)
 */
export interface ActionInterface {
  error: boolean;
  meta: {
    timestamp: Date;
  };
  payload: any; // tslint:disable-line no-any
  type: string;
}

interface ErrorActionInterface extends ActionInterface {
  payload: Error;
}

export class SuccessAction implements ActionInterface {
  public error: boolean = false;
  public meta: {
    timestamp: Date;
  };
  public payload: any; // tslint:disable-line no-any
  public type: string;

  constructor(
    type: string = '',
    payload: any = {}, // tslint:disable-line no-any
  ) {
    this.meta = merge({}, this.meta, { timestamp: new Date() });
    this.payload = payload;
    this.type = type;
  }

  public get json(): ActionInterface {
    return {
      error: this.error,
      meta: this.meta,
      payload: this.payload,
      type: this.type,
    };
  }
}

export class ErrorAction extends SuccessAction implements ErrorActionInterface {
  public error: boolean = true;
  public payload: Error;

  constructor(type: string = '', payload: Error = new Error('Unknown error')) {
    super(type, payload);
  }
}
